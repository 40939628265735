<template>
	<div class="contentBox">
		<div class="rowBox clearfix">
			<div class="titForTips">
				仓库统计
			</div>
			<div class="storeroom">
				<div class="storeroom_list" v-for="(item, index) in warehouseList" :key="index"
					@click="goWarehouse(item)">
					<div class="storeroom_title">
						<image class="storeroom_icon" src="../../../../static/images/box@2x.png" mode=""></image>
						<div class="storeroom_name">{{ item.warehouseName }}</div>
					</div>
					<div class="storeroom_content">
						<template v-if="item.boxNum || item.packNum">
							<div class="storeroom_num" v-if="item.boxNum">{{ item.boxNum }}箱</div>
							<span class="storeroom_add" v-if="item.boxNum && item.packNum">+</span>
							<div class="storeroom_total" v-if="item.packNum">{{ item.packNum }}盒</div>
						</template>
						<template v-else>
							<div class="storeroom_num">暂无</div>
						</template>
					</div>
				</div>
			</div>
		</div>
		<div class="rowBox clearfix">
			<!-- <div class="titForTips">
                出入库统计
            </div> -->
			<div class="scansBox clearfix">
				<div class="scanItem scanItem1" :class="{ 'newsItems': companyMsg.isHaveNfc }" @click="goAccess('1')">
					<div class="dataTipsBox">
						<img src="@/assets/images/scan/icon_smtj2x.png" alt="" class="tipBackImg">
						<div class="tipsTitles">入库统计</div>
					</div>
					<div class="codeItem">
						<div class="codeNumber yell_Color" id="num5">
							<span>{{ rkData.boxNum }}</span>
							<span>箱</span>
							<span class="add" v-if="rkData.packNum">+</span>
							<span v-if="rkData.packNum">{{ rkData.packNum }}</span>
							<span v-if="rkData.packNum">盒</span>
						</div>
						<div class="codeTitle">总入库量</div>
					</div>
					<div class="forLines"></div>
					<div class="codeItem">
						<div class="codeNumber yell_Color" id="num7">
							<span>{{ rkData.todayBoxNum }}</span>
							<span>箱</span>
							<span class="add" v-if="rkData.todayPackNum">+</span>
							<span v-if="rkData.todayPackNum">{{ rkData.todayPackNum }}</span>
							<span v-if="rkData.todayPackNum">盒</span>
						</div>
						<div class="codeTitle">今日入库量</div>
					</div>
					<div class="forLines"></div>
					<div class="codeItem">
						<div class="codeNumber yell_Color" id="num7">
							<span>{{ rkData.threeBoxNum }}</span>
							<span>箱</span>
							<span class="add" v-if="rkData.threePackNum">+</span>
							<span v-if="rkData.threePackNum">{{ rkData.threePackNum }}</span>
							<span v-if="rkData.threePackNum">盒</span>
						</div>
						<div class="codeTitle">近三日入库量</div>
					</div>
				</div>
				<div class="scanItem scanItem2" :class="{ 'newsItems': companyMsg.isHaveNfc }" @click="goAccess('2')">
					<div class="dataTipsBox">
						<img src="@/assets/images/scan/icon_yztj2x.png" alt="" class="tipBackImg">
						<div class="tipsTitles">出库统计</div>
					</div>
					<div class="codeItem">
						<div class="codeNumber gre_color" id="num9">
							<span>{{ ckData.boxNum }}</span>
							<span>箱</span>
							<span class="add" v-if="ckData.packNum">+</span>
							<span v-if="ckData.packNum">{{ ckData.packNum }}</span>
							<span v-if="ckData.packNum">盒</span>
						</div>
						<div class="codeTitle">总出库量</div>
					</div>
					<div class="forLines"></div>
					<div class="codeItem">
						<div class="codeNumber gre_color" id="num10">
							<span>{{ ckData.todayBoxNum }}</span>
							<span>箱</span>
							<span class="add" v-if="ckData.todayPackNum">+</span>
							<span v-if="ckData.todayPackNum">{{ ckData.todayPackNum }}</span>
							<span v-if="ckData.todayPackNum">盒</span>
						</div>
						<div class="codeTitle">今日出库量</div>
					</div>
					<div class="codeItem">
						<div class="codeNumber gre_color" id="num11">
							<span>{{ ckData.threeBoxNum }}</span>
							<span>箱</span>
							<span class="add" v-if="ckData.threePackNum">+</span>
							<span v-if="ckData.threePackNum">{{ rkData.threePackNum }}</span>
							<span v-if="ckData.threePackNum">盒</span>
						</div>
						<div class="codeTitle">近三日出库量</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rowBox clearfix rowBox1">
			<div id="chartBox" class="echartBox"></div>
		</div>
		<warehouseDialog ref="warehouseDialog" />
		<accessDialog ref="accessDialog" />
	</div>
</template>

<script>
	import echarts from "echarts";
	import {
		myWarehouse,
		getCrkStastics,
		selectCrkLine
	} from "@/api/crk";
	import {
		fetchCompany
	} from '@/api/companyManage';
	import warehouseDialog from '../detailDialog/warehouseDialog'
	import accessDialog from '../detailDialog/accessDialog'
	export default {
		data() {
			return {
				companyMsg: {},
				warehouseList: [],
				rkData: {
					boxNum: 0,
					packNum: 0,
					threeBoxNum: 0,
					threePackNum: 0,
					todayBoxNum: 0,
					todayPackNum: 0
				},
				ckData: {
					boxNum: 0,
					packNum: 0,
					threeBoxNum: 0,
					threePackNum: 0,
					todayBoxNum: 0,
					todayPackNum: 0
				}
			}
		},
		components: {
			warehouseDialog,
			accessDialog
		},
		mounted() {
			this.getCompany()
			this.getData()
			this.getChart()
		},
		beforeDestroy() {

		},
		methods: {
			goAccess(type) {
				this.$refs.accessDialog.openDialog(type)
			},
			goWarehouse(item) {
				this.$refs.warehouseDialog.openDialog(item)
			},
			getCompany() {
				let params = {
					myCardId: JSON.parse(localStorage.getItem('info')).cardId
				}
				if(params.myCardId) {
					fetchCompany(params)
					.then(res => {
						this.companyMsg = res.data
						console.log('companyMsg', this.companyMsg)
					})
				}
			},
			getData() {
				myWarehouse().then(res => {
					if (res.code == 200) {
						this.warehouseList = res.data
					}
				})
				getCrkStastics({
					wareStatus: 1
				}).then(res => {
					if (res.code == 200 && res.data) {
						this.rkData = res.data
					}

				})
				getCrkStastics({
					wareStatus: 2
				}).then(res => {
					if (res.code == 200 && res.data) {
						this.ckData = res.data
					}

				})
			},
			getChart() {
				selectCrkLine({
					day: 15
				}).then(res => {
					var timeList = []
					var inList = []
					var outList = []
					var inList1 = []
					var outList1 = []
					if (res.code == 200) {
						res.data.map(item => {
							inList.push(item.inBoxNum)
							outList.push(item.outBoxNum)
							inList1.push(item.inPackNum)
							outList1.push(item.outPackNum)
							timeList.push(item.time)
						})
			        this.$nextTick(() => {
						    this.fetChart(inList, outList, timeList, inList1, outList1)
							})
					}
				})

			},
			fetChart(inList, outList, timeList, inList1, outList1) {
				var chartBox = echarts.init(document.getElementById('chartBox'));
				var option = {
					color: ['#FF8438', '#08A89B', '#FFA500', '#3CB371'],
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['入库箱数', '出库箱数', '入库盒数', '出库盒数']
					},
					// toolbox: {
					//     show: true,
					//     feature: {
					//         dataView: { show: true, readOnly: false },
					//         magicType: { show: true, type: ['line', 'bar'] },
					//         restore: { show: true },
					//         saveAsImage: { show: true }
					//     }
					// },
					calculable: true,
					xAxis: [{
						type: 'category',
						// prettier-ignore
						data: timeList
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							name: '入库箱数',
							type: 'bar',
							data: inList,
							// markPoint: {
							//     data: [
							//         { type: 'max', name: 'Max' },
							//         { type: 'min', name: 'Min' }
							//     ]
							// },
							// markLine: {
							//     data: [{ type: 'average', name: 'Avg' }]
							// }
						},
						{
							name: '出库箱数',
							type: 'bar',
							data: outList,
							// markPoint: {
							//     data: [
							//         { type: 'max', name: 'Max' },
							//         { type: 'min', name: 'Min' }
							//     ]
							// },
							// markLine: {
							//     data: [{ type: 'average', name: 'Avg' }]
							// }
						},
						{
							name: '入库盒数',
							type: 'bar',
							data: inList1,
							// markPoint: {
							//     data: [
							//         { type: 'max', name: 'Max' },
							//         { type: 'min', name: 'Min' }
							//     ]
							// },
							// markLine: {
							//     data: [{ type: 'average', name: 'Avg' }]
							// }
						},
						{
							name: '出库盒数',
							type: 'bar',
							data: outList1,
							// markPoint: {
							//     data: [
							//         { type: 'max', name: 'Max' },
							//         { type: 'min', name: 'Min' }
							//     ]
							// },
							// markLine: {
							//     data: [{ type: 'average', name: 'Avg' }]
							// }
						}
					]
				};
				chartBox.setOption(option);
				chartBox.resize();
				window.addEventListener("resize", () => {
					chartBox.resize();
				});
			}
		}
	}
</script>

<style scoped>
	.contentBox {
		width: 100%;
		margin-bottom: 20px;

	}

	.rowBox {
		width: 100%;
		box-sizing: border-box;
		padding: 0 20px;
		margin: 0 auto 20px;
		background: #fff;
	}

	.ibox {
		clear: both;
		padding: 0;
		background: #fff;
		height: auto;
		position: relative;

	}

	.titForTips {
		font-size: 18px;
		height: 40px;
		line-height: 30px;
		padding-top: 9px;
		position: relative;
		z-index: 2;
	}

	.titForTips::after {
		content: "";
		display: block;
		width: 80px;
		height: 11px;
		background: rgba(226, 234, 255, 0.5);
		position: absolute;
		z-index: -1;
		left: 25px;
		bottom: 0;
	}

	.storeroom {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		padding-top: 48rpx;
		margin: 15px 0;
	}

	.storeroom_list {
		display: inline-block;
		width: 200px;
		background: #F5F9FC;
		border-radius: 20px;
		z-index: 99;
		position: relative;
		padding-bottom: 10px;
		cursor: pointer;
		margin-bottom: 20px;
		margin-right: 20px;
	}

	/* .storeroom .storeroom_list:nth-child(odd) {
		margin-right: 20px;
	} */

	.storeroom_icon {
		width: 29px;
		height: 29px;
		margin-right: 10px;
	}

	.storeroom_title {
		width: fit-content;
		padding: 4px 10px;
		background: linear-gradient(143deg, #2124f7, #2CEBF7);
		border-radius: 20px 0 20px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.storeroom_name {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #FFFFFF;
	}

	.storeroom_content {
		box-sizing: border-box;
		margin-top: 15px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 16px;
	}

	.storeroom_num,
	.storeroom_total {
		font-size: 16px;
		font-family: DIN;
		font-weight: 500;
		color: #6E798C;
		box-sizing: border-box;
		padding: 0 5px;
		position: relative;
	}

	.storeroom_content .storeroom_add {
		color: #4E7FFF;
		font-weight: bold;
	}

	.scansBox {
		width: 100%;
		height: auto;
		background-color: #fff;
		margin: 5px auto;
		padding: 20px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.scanItem {
		width: 50%;
		background-color: #ffffff;
		float: left;
		position: relative;
		padding-top: 35px;
		margin-top: 0;
		height: auto;
		padding-bottom: 20rpx;
	}

	.newsItems {
		width: 50%;
	}

	.dataTipsBox {
		position: absolute;
		left: 0;
		top: 0;
	}

	.tipBackImg {
		/* width: 7rem;
	    height: auto; */
		width: 112px;
		height: 30px;
		display: block;
		float: left;
	}

	.tipsTitles {
		position: absolute;
		/* left: 1.3rem;
	    top: 0.26rem; */
		left: 20px;
		top: 3px;
		color: #fff;
	}

	.codeItem {
		width: 31%;
		height: 90px;
		float: left;
		margin-right: 1%;
		margin-left: 1%;
		cursor: pointer;
	}

	.scanItem1 {
		margin-right: 10px
	}

	.scanItem2 {
		margin-left: 10px
	}

	.scanItem1 .codeItem:hover,
	.scanItem4 .codeItem:hover {
		background-color: #fefbf6;
	}

	.scanItem2 .codeItem:hover {
		background-color: #f5faf4;
	}

	.scanItem3 .codeItem:hover {
		background-color: #f5f9fb;
	}

	.forLines {
		width: 2px;
		height: 30px;
		border-left: 1px dashed #e8e8e8;
		float: left;
		margin-top: 35px;
	}

	.codeNumber {
		/* font-size: 1.75rem;
	    line-height: 3.2rem; */
		font-size: 25px;
		line-height: 35px;
		text-align: center;
		margin-top: 5px;
	}

	.codeTitle {
		font-size: 16px;
		color: #aaa;
		/* line-height: 1.8rem; */
		line-height: 25px;
		text-align: center;
	}

	.yell_Color {
		color: #FF8438;
	}

	.gre_color {
		color: #08A89B;
	}

	.nfc_color {
		color: #05c944;
	}

	.echartBox {
		width: 100%;
		height: 400px;
	}

	.rowBox1 {
		width: 100%;
		box-sizing: border-box;
		padding: 20px 0;
	}
</style>

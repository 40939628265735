<template>
    <div>
        <div class="tables">
            <div class="title cblue tr fs12">
                <span class="fblod">场景名称</span>
                <span>产品码</span>
                <span>箱标码</span>
                <span>盒标码</span>
                <span>物料码</span>
                <span>名片码</span>
                <span>证书码</span>
            </div>
            <div class="tr fs12 ca8">
                <span>扫码人数</span>
                <span>{{scanNum(userData.product)}}</span>
                <span>{{scanNum(userData.boxMark)}}</span>
                <span>{{scanNum(userData.pack)}}</span>
                <span>{{scanNum(userData.material)}}</span>
                <span>{{scanNum(userData.businessCard)}}</span>
                <span>{{scanNum(userData.credentials)}}</span>
            </div>
            <div class="tr fs12 ca8">
                <span>验证人数</span>
                <span>{{verificationNum(userData.product)}}</span>
                <span>{{verificationNum(userData.boxMark)}}</span>
                <span>{{verificationNum(userData.pack)}}</span>
                <span>{{verificationNum(userData.material)}}</span>
                <span>{{verificationNum(userData.businessCard)}}</span>
                <span>{{verificationNum(userData.credentials)}}</span>
            </div>
            <div class="tr fs12 ca8">
                <span>购买人数</span>
                <span>{{buyNum(userData.product)}}</span>
                <span>{{buyNum(userData.boxMark)}}</span>
                <span>{{buyNum(userData.pack)}}</span>
                <span>{{buyNum(userData.material)}}</span>
                <span>{{buyNum(userData.businessCard)}}</span>
                <span>{{buyNum(userData.credentials)}}</span>
            </div>
        </div>

    </div>
</template>

<script>
    import echarts from "echarts";
    import { fetchActualData } from "@/api/scanStatics";
    export default {
        name: "DataTable",
        data() {
            return {
                userData: {}
            }
        },
        props: {
            typeNums: {
                type: Number,
                default: 1
            },
        },
        computed: {
            isShow() {
                return this.typeNums
            }
        },
        watch: {
            isShow: { //深度监听，可监听到对象、数组的变化
                handler(newV, oldV) {
                    // do something, 可使用this
                    console.log(newV, oldV)
                    // this.typeNums = newV
                    this.fetchProductData()
                },
                deep: true
            }
        },
        methods: {
            scanNum(name) {
                return name ? name.scanNum : '-'
            },
            verificationNum(name) {
                return name ? name.verificationNum : '-'
            },
            buyNum(name) {
                return name ? name.buyNum : '-'
            },
            fetchProductData() {
                var nums = 1
                // alert(this.typeNums)
                if (this.typeNums == 1) {
                    nums = 0
                } else if (this.typeNums == 2) {
                    nums = 1
                } else if (this.typeNums == 3) {
                    nums = 7
                } else if (this.typeNums == 4) {
                    nums = 30
                }
                let  cId = JSON.parse(localStorage.getItem('info')).companyId
                fetchActualData({day: nums, cId: cId}).then(response => {
                    console.log('response',response)
                    if (response) {
                        this.userData = response.data
                    } else {

                    }
                }).catch(() => {})
            }
        },
        mounted() {
            this.fetchProductData()
        }
    }
</script>

<style scoped>
    .tables {
        padding: 0 15px;
        width: 98%;
        margin: auto;
    }

    .tables .tr {
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .tables .tr:nth-of-type(2n) {
        background-color: #fff;
    }

    .tables .tr:nth-of-type(2n + 1) {
        background-color: #F7F8FB;
    }

    .tables .title {
        border-radius: 20px 20px 0 0;
        background-color: #EDF4FF;
    }

    .tables .tr span:nth-of-type(n + 1) {
        width: 16%;
        text-align: center;
    }

    .tables .title span {
        color: #427EFF;
    }

    .tables .tr span:nth-of-type(1) {
        font-weight: bold;
        /* color: #787878; */
        width: 20%;
    }

    /* 	.tables .title span:nth-of-type(1) {
      font-weight: bold;
      color: #3b96f8;
    } */
    .fs12 {
        font-size: 14px;
    }

    .ca8 {
        color: #4E596B;
    }
</style>

<template>
	<div>
		<div class=" topBox">
			<div class="topBoxItem" style="margin-left: 0px">
				<div class="topBoxImage">
					<img src="../../assets/images/icon_zs.png">
				</div>
				<div class="topBoxContent" @click="showDetail(0)">
					<div class="topBoxLabel">标识总数</div>
					<div class="topBoxNum">{{ mainData.allNum ? mainData.allNum : 0 }}</div>
				</div>
			</div>
			<div class="topBoxItem">
				<div class="topBoxImage">
					<img src="../../assets/images/icon_sm.png">
				</div>
				<div class="topBoxContent" @click="showDetail(2)">
					<div class="topBoxLabel">扫码数</div>
					<div class="topBoxNum">{{ mainData.scanNum ? mainData.scanNum : 0 }}</div>
				</div>
			</div>
			<div class="topBoxItem">
				<div class="topBoxImage">
					<img src="../../assets/images/icon_yjh.png">
				</div>
				<div class="topBoxContent" @click="showDetail(1)">
					<div class="topBoxLabel">已激活数</div>
					<div class="topBoxNum">{{ mainData.activeNum ? mainData.activeNum : 0 }}</div>
				</div>
			</div>
			<div class="topBoxItem">
				<div class="topBoxImage">
					<img src="../../assets/images/icon_wjh.png">
				</div>
				<div class="topBoxContent" @click="showDetail(3)">
					<div class="topBoxLabel">未激活数</div>
					<div class="topBoxNum">{{ mainData.noActiveNum ? mainData.noActiveNum : 0 }}</div>
				</div>
			</div>
		</div>

		<div class="wrapBox" style="margin-bottom: 0;">
			<div class="topTitle"></div>
			<div class="topBox topBox2">
				<div class="modelChoose">
          <div style="display: inline-block;font-size: 16px">类型：</div>
          <el-select v-model="classId" placeholder="请选择" @change="chooseModelClass()">
            <el-option v-for="item in modelClassList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
					<div style="display: inline-block;font-size: 16px;margin-left: 20px">模板：</div>
					<el-select v-model="modelId" placeholder="请选择" @change="chooseModel()">
						<el-option v-for="item in modelList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
					<div style="display: inline-block;font-size: 16px;margin-left: 20px" class="demonstration">时间：</div>
					<el-date-picker @change="changeDate" v-model="time" value-format="yyyy-MM-dd" type="daterange"
						:picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
						align="right">
					</el-date-picker>
				</div>
			</div>

			<div class="typeWrap">
					<div class="typeList">
						<div class="topTitle"><img src="../../assets/images/icon1.png" class="rytTitIcon" />实时状态数据统计</div>
						<div class="stateDiv" @click="stateTjDetail(stateListInfo.allNum, 2)">
							<div class="codeNumber ">{{ stateListInfo.allNum ? stateListInfo.allNum : 0 }}</div>
							<div class="codeTitle">总数</div>
						</div>
						<div class="stateDiv" @click="stateTjDetail(state.state, 3)"
							v-for="(state, index) in stateListInfo.stateList" :key="index">
							<div class="codeNumber ">{{ state.num ? state.num : 0 }}</div>
							<div class="codeTitle">{{ state.state }}</div>
						</div>
					</div>
					<!-- <el-divider direction="vertical"
						v-if="stateListInfo.stateList && stateListInfo.stateList.length > 0"></el-divider> -->
					<div class="splitDiv"></div>
					<div class="typeList">
						<div class="topTitle"><img src="../../assets/images/icon2.png" class="rytTitIcon" />历史操作数据统计</div>
						<div v-for="(state, index) in stateList" :key="index" class="stateDiv" @click="stateTjDetail(state, 1)">
							<div class="codeNumber ">{{ state.num ? state.num : 0 }}</div>
							<div class="codeTitle">{{ state.state }}</div>
						</div>
					</div>
			</div>
		</div>

		<!-- <div class="wrapBox">
      <div class="lengeBox">
        <div class="linesForBox">
          <div class="scanShows">
            <div class="newsNumss" id="sevenScanNums"></div>
            <div class="topTitle">近10日扫码量</div>
          </div>
        </div>
        <div class="echarts1" id="echarts1">

        </div>
      </div>
    </div> -->
    <div class="wrapBox" style="margin-bottom: 0;">
      <div class="lengeBox">
        <div class="linesForBox">
          <div class="scanShows">
            <div class="newsNumss" id="sevenScanNums"></div>
            <div class="topTitle"><img src="../../assets/images/phb.png" class="rytTitIcon" />历史操作排行榜</div>
          </div>
        </div>
        <btn-top ref="btnTop"></btn-top>
      </div>
    </div>
		<div class="wrapBox" style="margin-bottom: 0;">
			<div class="lengeBox">
				<div class="linesForBox">
					<div class="scanShows">
						<div class="newsNumss" id="sevenScanNums"></div>
						<div class="topTitle"><img src="../../assets/images/icon3.png" class="rytTitIcon" />每日状态数据统计</div>
					</div>
				</div>
				<div class="echarts1" id="echarts12">
				</div>
			</div>
		</div>

		<div class="wrapBox">
			<div class="lengeBox">
				<div class="linesForBox">
					<div class="scanShows">
						<div class="newsNumss" id="sevenScanNums"></div>
						<div class="topTitle"><img src="../../assets/images/icon4.png" class="rytTitIcon" />每日操作数据统计</div>
					</div>
				</div>
				<div class="echarts1" id="echarts13">
				</div>
			</div>
		</div>


		<div class="wrapBox">
			<div class="topTitle">排行榜top5</div>
			<div class="topWrap">
				<el-table :data="topData" style="width: 100%;"
					:header-cell-style="{ 'text-align': 'center', 'font-size': '14px', 'font-family': 'AppleSystemUIFont', 'color': '#425063', 'line-height': '17px' }"
					:cell-style="{ 'text-align': 'center', background: 'revert' }" :row-class-name="tableRowClassName">
					<el-table-column prop="" align="center" label="排名" width="100">
						<template slot-scope="scope">
							<div class="circleWrap"
								v-bind:class="[scope.$index + 1 == '1' ? 'index_one' : scope.$index + 1 == '2' ? 'index_two' : scope.$index + 1 == '3' ? 'index_three' : 'index_other']">
								<div class="circle">
									0{{ scope.$index + 1 }}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column align="center" :show-overflow-tooltip="true" prop="title" label="标题" />
					<el-table-column align="center" :show-overflow-tooltip="true" prop="handle" label="标识" >
            <template slot-scope="scope">
              <span class="handle tag-read" :data-clipboard-text="cHandle" @click="copyHandle(scope.row.handle)">{{scope.row.handle}}</span>
            </template>
          </el-table-column>
					<el-table-column align="center" :show-overflow-tooltip="true" prop="scanNum" label="扫码数量">
						<template slot-scope="scope">
							<span class="textBtn" @click="showTopDetailDialog(scope.row)">{{ scope.row.scanNum }}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<!--数据统计弹窗-->
		<el-dialog :title="title" :visible.sync="dialogVisible" :close-on-click-modal="false" width="1200px" append-to-body>
			<commonTable :tableData="detailData" @handleSizeChange="handleSizeChange"
				@handleCurrentChange="handleCurrentChange" :currentPage="pageNum" :loading="loading" :total="total">
				<template v-slot:table>
          <el-table-column v-if="flag == 2" align="center" :show-overflow-tooltip="true" prop="createTime" label="扫码时间" />
					<el-table-column align="center" :show-overflow-tooltip="true" prop="handle" label="标识" >
            <template slot-scope="scope">
              <span class="handle tag-read" :data-clipboard-text="cHandle" @click="copyHandle(scope.row.handle)">{{scope.row.handle}}</span>
            </template>
          </el-table-column>
					<el-table-column align="center" prop="title" label="二维码">
						<template slot-scope="scope">
							<vue-qr :size="50" :margin="0" :auto-color="true" :dot-scale="1"
								:text="`https://api.86122m.com/ryt?ryt=${scope.row.handle}`" class="codeImage" />
						</template>
					</el-table-column>
					<el-table-column align="center" :show-overflow-tooltip="true" v-if="flag !== 3" prop="title"
						label="名称" />
          <el-table-column align="center" :show-overflow-tooltip="true"  prop="className" v-if="flag !== 3"
                           label="类型" />
					<el-table-column align="center" :show-overflow-tooltip="true" prop="scanNum" v-if="flag !== 3"
						label="扫码数量" />
					<el-table-column align="center" :show-overflow-tooltip="true" prop="status" :key="1" label="状态" v-if="flag <= 0">
						<template slot-scope="scope">
							<span class="green" v-if="scope.row.status == 1">
								已激活
							</span>
							<span class="grey" v-else>
								未激活
							</span>
						</template>
					</el-table-column>
					<el-table-column align="center" :show-overflow-tooltip="true" v-if="flag !== 3" prop="activeTime"
						label="激活时间" />
					<el-table-column align="center" :show-overflow-tooltip="true" v-if="flag !== 3" prop="nickeName"
						label="管理员" />
          <el-table-column align="center" :show-overflow-tooltip="true" v-if="flag !== 3" prop="memberUser"
                           label="成员" />
					<el-table-column align="center" :show-overflow-tooltip="true" prop="createTime" label="创建时间" v-if="flag == 3"/>
				</template>
			</commonTable>
		</el-dialog>

		<!--top详情数据-->
		<el-dialog title="排行榜" :visible.sync="topDetailVisible" :close-on-click-modal="false" width="800px" append-to-body>
			<commonTable :tableData="topDetailData" @handleSizeChange="handleSizeChange2"
				@handleCurrentChange="handleCurrentChange2" :currentPage="topPageNum" :loading="loading" :total="topTotal">
				<template v-slot:table>
					<el-table-column align="center" :show-overflow-tooltip="true" prop="handle" label="标识" >
            <template slot-scope="scope">
              <span class="handle tag-read" :data-clipboard-text="cHandle" @click="copyHandle(scope.row.handle)">{{scope.row.handle}}</span>
            </template>
          </el-table-column>
					<el-table-column align="center" :show-overflow-tooltip="true" prop="" label="头像">
						<template slot-scope="scope">
							<img :src="scope.row.avatarUrl" class="avatar" />
						</template>
					</el-table-column>
					<el-table-column align="center" :show-overflow-tooltip="true" prop="nickeName" label="昵称" />
					<el-table-column align="center" :show-overflow-tooltip="true" prop="phone" label="手机号" />
					<el-table-column align="center" :show-overflow-tooltip="true" prop="createTime" label="扫码时间" />
				</template>
			</commonTable>
		</el-dialog>

		<el-dialog title="详情" :visible.sync="stateDialogVisible" :close-on-click-modal="false" width="1200px"
			append-to-body>
			<template v-if="type == 1">
        <div style="display: inline-block;font-size: 16px;margin-left: 20px" class="demonstration">时间：</div>
        <el-date-picker @change="changeDate" v-model="time" value-format="yyyy-MM-dd" type="daterange"
                        :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
        </el-date-picker>
        <el-button class="filter-item" size="mini" type="warning" icon="el-icon-download"
                   @click="exportToExcel">导出
        </el-button>
        <commonTable :tableData="stateDetailData" @handleSizeChange="stateHandleSizeChange"
                     @handleCurrentChange="stateHandleCurrentChange" :currentPage="statePageNum" :total="stateTotal">
          <template v-slot:table>
            <el-table-column :show-overflow-tooltip="true" prop="title" label="名称" align="center"/>
            <el-table-column :show-overflow-tooltip="true" prop="handle" label="标识" align="center">
              <template slot-scope="scope">
                <span class="handle tag-read" :data-clipboard-text="cHandle" @click="copyHandle(scope.row.handle)">{{scope.row.handle}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="二维码" align="center">
              <template slot-scope="scope">
                <vue-qr :size="50" :margin="0" :auto-color="true" :dot-scale="1"
                        :text="`https://api.86122m.com/ryt?ryt=${scope.row.handle}`" class="codeImage"/>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="nowState" label="操作" align="center"/>
            <el-table-column prop="content" label="内容" align="center" width="300px">
							<template slot-scope="scope">
								<div v-for="(item,index) in scope.row.content" :key="index" style="text-aligin: left">
									<div v-if="item.type === 1" class="cItem">
										{{item.title}}: {{item.value}}
									</div>
									<div v-if="item.type === 2" style="">
										<div style="text-aligin: left;display:flex;">{{item.title}}:</div>
										<div style="text-aligin: left; display:flex;">
											<el-image :fit="contain" :src="baseImg+imgItem.src" :preview-src-list="[baseImg+imgItem.src]" v-for="imgItem in item.imgList" :key="imgItem.src" class="conImg"></el-image>
										</div>
									</div>
								</div>
							</template>
						</el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="nickName" label="操作人" align="center"/>
            <el-table-column :show-overflow-tooltip="true" prop="createTime" label="操作时间" align="center"/>
            <el-table-column :show-overflow-tooltip="true" prop="stateName" label="状态" align="center"/>
          </template>
        </commonTable>
			</template>
			<template v-else>
				<commonTable :tableData="stateDetailData" @handleSizeChange="stateHandleSizeChange"
					@handleCurrentChange="stateHandleCurrentChange" :currentPage="statePageNum" :total="stateTotal">
					<template v-slot:table>
						<el-table-column :show-overflow-tooltip="true" prop="title" label="名称" align="center" />
						<el-table-column :show-overflow-tooltip="true" prop="handle" label="标识" align="center" >
              <template slot-scope="scope">
                <span class="handle tag-read" :data-clipboard-text="cHandle" @click="copyHandle(scope.row.handle)">{{scope.row.handle}}</span>
              </template>
            </el-table-column>

						<el-table-column prop="title" label="二维码" align="center">
							<template slot-scope="scope">
								<vue-qr :size="50" :margin="0" :auto-color="true" :dot-scale="1"
									:text="`https://api.86122m.com/ryt?ryt=${scope.row.handle}`" class="codeImage" />
							</template>
						</el-table-column>
						<el-table-column :show-overflow-tooltip="true" prop="adminUser" label="管理员" align="center" />
            <el-table-column align="center" :show-overflow-tooltip="true"  prop="memberUser" label="成员" />
						<el-table-column :show-overflow-tooltip="true" prop="activeTime" label="激活时间" align="center" />
					</template>
				</commonTable>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import ExcelJS from 'exceljs'
import {
	fetchZdyScanDetail,
	fetchAllTypes,
	fetchZdyScanData,
	fetchZdyTJ,
	fetchZdyTjDetail,
	fetchZdyTopData,
	fetchRytModelList,
	fetchRytState,
	fetchRytStateModelList,
	fetchRytStateTjDetailList,
	fetchRytStateInfo,
	rytBtnStateLine,
	rytBtnLine,
	fetchgetPage,getCompanyClass
} from '@/api/ryt.js'
import echarts from 'echarts'
import VueQr from 'vue-qr'
import commonTable from "@/components/common/commonTable"
import BtnTop from "./components/BtnTop.vue";
  import Clipboard from 'clipboard'
export default {
	components: {
		VueQr,
		commonTable,BtnTop
	},
	data() {
		return {
      activeName:'first',
			pickerOptions: {
				shortcuts: [{
					text: '今日',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '昨日',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
						picker.$emit('pick', [start, start]);
					}
				}, {
					text: '近七日',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			time: [],
			time1: [],
			stateDialogVisible: false,
			stateDetailData: [],
			topTypeId: '',
			typesOptions: [],
			topData: [],
			lineTypeId: '',
			mainTypeId: '',
			mainData: {},
			title: '数据详情',
			dialogVisible: false,
			pageNum: 1,
			pageSize: 10,
			total: 0,
			detailData: [],
			topDetailVisible: false,
			loading: false,
			topPageNum: 1,
			topPageSize: 10,
			topTotal: 0,
			topDetailData: [],
			topHandle: '',
			modelList: [],
			modelId: null,
			stateList: [],
			statePageNum: 1,
			statePageSize: 10,
			stateTotal: 0,
			stateSearchParam: {
				startTime: '',
				endTime: ''
			},
			dialogInfo: {
				startTime: '',
				endTime: ''
			},
			stateListInfo: [],
			type: null,
			excelName: '',
      btnLabel:'操作排行榜',
      modelClassList:[],
      classId:null,
			cHandle:'',
      baseImg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/',
		}
	},
	mounted() {
		this.init()
	},
	methods: {
    copyHandle(data) {
      this.cHandle = data // data.customPageId
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {

      })
      clipboard.on('error', e => {
        // 不支持复制
        // 释放内存
        // clipboard.listener.destory()
        this.$message({
          type: 'warning',
          message: '该浏览器不支持复制'
        });
      })
      this.$message({
        type: 'success',
        message: '复制成功'
      });
    },
    chooseModelClass(){
      this.getModel()
    },
    getCompanyClass(){
      getCompanyClass().then(res=>{
        this.modelClassList = res
        if (this.modelClassList.length > 0){
          this.classId = this.modelClassList[0].id
          this.getModel()
        }
      })
    },
    handleTabClick(tab, event) {
     this.getBtnList()
    },

		changeDate() {
			if (this.time) {
				this.stateSearchParam.startTime = this.time[0]
				this.stateSearchParam.endTime = this.time[1]
			} else {
				this.stateSearchParam.startTime = null
				this.stateSearchParam.endTime = null
			}
			this.chooseModel()
		},
		handleSizeChange(num) {
			this.pageSize = num;
			this.pageNum = 1;
			this.getDetailData();
		},
		handleSizeChange2(num) {
			this.topPageSize = num;
			this.topPageNum = 1;
			this.fetchZdyScanDetail();
		},

		stateHandleSizeChange(num) {
			this.statePageSize = num;
			this.statePageNum = 1;
			this.getStateDetail();
		},
		1() {
			console.log(Math.randow())
			return Math.randow();
		},
		tableRowStyle({
			row,
			rowIndex
		}) {
			return `
            color: #fff;
            outline: 1px red solid;
           `
		},
		tableRowClassName(row, rowIndex) {
			if ((row.rowIndex + 1) % 2 != 0) {
				return 'jishurow';
			} else if ((row.rowIndex + 1) % 2 == 0) {
				return 'oshurow';
			}
			return '';
		},
		showTopDetailDialog(data) {
			if (data && data.handle) {
				this.topHandle = data.handle
			}
			let params = {
				handle: this.topHandle,
				pageNum: this.topPageNum,
				pageSize: this.topPageSize
			}
			fetchZdyScanDetail(params).then(res => {
				this.topDetailData = res.list
				this.topTotal = res.total
				this.topDetailVisible = true
			})
		},
		// getRytTypes() {
		//     fetchAllTypes().then(res => {
		//       if(res.data) {
		//         this.typesOptions = res.data
		//         let dafaultId = this.typesOptions.filter(item => item.typeName === '图书')[0].id
		//         this.topTypeId = dafaultId
		//         this.lineTypeId = dafaultId
		//         this.mainTypeId = dafaultId
		//         this.init()
		//       }
		//     })
		// },
		init() {
			this.getTopData()
			this.getChart1Data()

			this.getTypeTj()
      this.getCompanyClass()

		},
		changeDate1() {
			console.log(this.time1)
			if (this.time1) {
				this.dialogInfo.startTime = this.time1[0]
				this.dialogInfo.endTime = this.time1[1]
			} else {
				this.dialogInfo.startTime = null
				this.dialogInfo.endTime = null
			}
			this.getStateDetail()
		},
		stateTjDetail(state, type) {
			this.statePageNum = 1
			this.type = type
			this.state = type == 1 ? state.state : state
			this.getStateDetail()
			this.stateDialogVisible = true
			this.excelName = state.state
		},
		workList(arr) {
      let res = []
      arr.forEach(item => {
        if(item.src) {
          res.push(item)
        }
      })
      return res
    },
		getStateDetail() {
			if (this.type == 1) {
				let params = {
					modelId: this.modelId,
					state: this.state,
					pageNum: this.statePageNum,
					pageSize: this.statePageSize,
					type: this.type,
					// this.stateSearchParam.startTime = this.time[0]
					// this.stateSearchParam.endTime = this.time[1]
					startTime: this.stateSearchParam.startTime,
					endTime: this.stateSearchParam.endTime
				}
				fetchRytStateTjDetailList(params).then(response => {
					this.stateDetailData = response.list
					this.stateDetailData.forEach(item=>{
						item.content = JSON.parse(item.content)
						if(item.content) {
							for(let i=0; i< item.content.length; i++) {
								if(item.content[i].imgList) {
									item.content[i].imgList = this.workList(item.content[i].imgList)
								}
							}
						}
					})
					this.stateTotal = response.total
				})
			} else {
				let params = {}
				if (this.type == 2) {
					params = {
						modelId: this.modelId,
						pageNum: this.statePageNum,
						pageSize: this.statePageSize,
						startTime: this.stateSearchParam.startTime,
						endTime: this.stateSearchParam.endTime
					}
				} else {
					params = {
						modelId: this.modelId,
						state: this.state,
						pageNum: this.statePageNum,
						pageSize: this.statePageSize,
						startTime: this.stateSearchParam.startTime,
						endTime: this.stateSearchParam.endTime
					}
				}
				fetchgetPage(params).then(response => {
					this.stateDetailData = response.list
					this.stateTotal = response.total
				})
			}

		},
		async exportToExcel() {
			var worksData = []

			 let params = {
					modelId: this.modelId,
					state: this.state,
					pageNum: 1,
					pageSize: this.stateTotal,
					type: this.type,
					startTime: this.dialogInfo.startTime,
					endTime: this.dialogInfo.endTime
				}
				await fetchRytStateTjDetailList(params).then(response => {
					worksData = response.list
				})

		  const workbook = new ExcelJS.Workbook()
		  const worksheet = workbook.addWorksheet('QR Code')
		  // 添加表头
		  worksheet.columns = [{
		      header: '标题',
		      key: 'title',
		      width: 40
		    },
		    {
		      header: '标识',
		      key: 'handle',
		      width: 30
		    },
		    {
		      header: '操作',
		      key: 'nowState',
		      width: 30
		    },
		    {
		      header: '操作人',
		      key: 'nickName',
		      width: 40
		    },
		    {
		      header: '操作时间',
		      key: 'createTime',
		      width: 60
		    },
				{
				  header: '状态',
				  key: 'stateName',
				  width: 30
				}
		  ]
		  worksheet.height = 20
		  // 添加数据行
		  worksData.forEach((item, index) => {
		      const row1 = worksheet.addRow({
		        title: item.title,
		      	handle: item.handle,
		      	nowState: item.nowState,
		      	nickName: item.nickName,
		      	createTime: item.createTime,
						stateName: item.stateName
		      })
		      row1.height = 20
		  })
		  const buffer = await workbook.xlsx.writeBuffer()
		  this.downloadExcelFile(buffer)
		},
		downloadExcelFile(buffer) {
		  const blob = new Blob([buffer], {
		    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		  })
		  const downloadLink = document.createElement('a')
		  const url = URL.createObjectURL(blob)
		  downloadLink.href = url
		  downloadLink.download = this.excelName + '信息.xlsx'
		  document.body.appendChild(downloadLink)
		  downloadLink.click()
		  document.body.removeChild(downloadLink)
		  URL.revokeObjectURL(url)
		},
    initBtnTop(){
      this.$refs.btnTop.modelId = this.modelId
      if (this.stateSearchParam.startTime && this.stateSearchParam.endTime){
        this.$refs.btnTop.stateSearchParam = this.stateSearchParam
      }
      this.$refs.btnTop.init()
    },
		chooseModel() {
			let params = {
				modelId: this.modelId,
				startTime: this.stateSearchParam.startTime,
				endTime: this.stateSearchParam.endTime,
			}
     this.initBtnTop()
			fetchRytState(params).then(response => {
				this.stateList = response
			})
			fetchRytStateInfo(params).then(response => {
				this.stateListInfo = response
				console.log('stateListInfo', response)
			})
			this.getChart2Data()
			this.getChart3Data()
		},
		getModel() {
      console.log(this.classId)
			fetchRytStateModelList({classId:this.classId}).then(response => {
				this.modelList = response
				this.modelId = this.modelList[0].id
				this.chooseModel();
			})
		},
		topChange(data) {
			this.topTypeId = data,
				this.getTopData()
		},
		lineTypeChange(data) {
			this.lineTypeId = data,
				this.getChart1Data()
		},
		mainTypeChange(data) {
			this.mainTypeId = data
			this.getTypeTj()
		},
		getTopData() {
			let params = {
				rytTypeId: this.topTypeId,
				num: 5,
			}
			fetchZdyTopData(params).then(res => {
				this.topData = res
				console.log(this.topData)
				console.log(this.topData[0].handle)
			})
		},
		getChart1Data() {
			let params = {
				num: 10
			}
			fetchZdyScanData(params).then(res => {
				let Xdata = res.map(item => item.time)
				let Ydata = res.map(item => item.num)
				this.drawChart1(Xdata, Ydata)
			})
		},
		getChart2Data() {

			rytBtnStateLine({
				modelId: this.modelId,
				startTime: this.stateSearchParam.startTime,
				endTime: this.stateSearchParam.endTime
			}).then(res => {
				console.log('seriesData2',res)
				let chart2 = echarts.init(document.getElementById('echarts12'));
				var seriesData = []
				var times = []
				res.map((item, index) => {
					seriesData.push({
						type: 'line',
						smooth: true,
						name: item.name
					})
					seriesData[index].data = []
					times.push([])
					item.lineVos.map((i, j) => {
						this.$set(seriesData[index].data, [j], i.num)
						this.$set(times[index], [j], i.time)
					})

				})
				var option = {
				  color: ['#98d5fb','#f4d28c','#989afb','#d58cf4','#f48ca2','#f0a080','#6bf4b5','#6b88f4','#FF8F00','#4C95F8','#08A89B','#784BFF'],
					toolbox: {
						show: true,
						feature: {
							saveAsImage: {}
						}
					},
					legend: {},
					grid: {
						top: 40,
						left: 40,
						bottom: 30,
						right: 40
						// containLabel: true
					},
					tooltip: {
						trigger: 'axis',
						formatter: function (params) {
							let str=params[0].axisValue
							for(let i=0; i<params.length; i++ ) {
								str+='<br/>'+params[i].marker + params[i].seriesName + params[i].value + '次'
							}
							return str
						}
					},
					xAxis: {
						type: 'category',
						data: times[0]
					},
					yAxis: {
						type: 'value',
						interval: 1,
					},
					series: seriesData
				};
				chart2.setOption(option, true)
				console.log('任意贴图表2')
				chart2.resize()
				 window.addEventListener("resize", () => {
			        chart2.resize();
			    });
			})
		},
		getChart3Data() {
			rytBtnLine({
				modelId: this.modelId,
				startTime: this.stateSearchParam.startTime,
				endTime: this.stateSearchParam.endTime
			}).then(res => {
				console.log('seriesData3',res)
				let chart3 = echarts.init(document.getElementById('echarts13'));
				var seriesData = []
				var times = []
				res.map((item, index) => {
					seriesData.push({
						type: 'bar',
						name: item.name
					})
					seriesData[index].data = []
					times.push([])
					item.lineVos.map((i, j) => {
						this.$set(seriesData[index].data, [j], i.num)
						this.$set(times[index], [j], i.time)
					})

				})

				console.log('times', times)
				console.log('seriesData', seriesData)
				var option = {
					color: ['#98d5fb','#f4d28c','#989afb','#d58cf4','#f48ca2','#f0a080','#6bf4b5','#6b88f4','#FF8F00','#4C95F8','#08A89B','#784BFF'],
					toolbox: {
						show: true,
						feature: {
							saveAsImage: {}
						}
					},
					grid: {
						top: 40,
						left: 40,
						bottom: 30,
						right: 40
						// containLabel: true
					},
					tooltip: {
						trigger: 'axis',
						formatter: function (params) {
							let str=params[0].axisValue
							for(let i=0; i<params.length; i++ ) {
								str+='<br/>'+params[i].marker + params[i].seriesName + params[i].value + '次'
							}
							console.log('str', str)
							return str
						}
					},
					legend: {},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: times[0]
					}],
					yAxis: [{
						type: 'value',
						interval: 1,
					}],
					series: seriesData
				};
				// var option = {
				// 	toolbox: {
				// 		show: true,
				// 		feature: {
				// 			saveAsImage: {}
				// 		}
				// 	},
				// 	legend: {},
				// 	grid: {
				// 		top: 40,
				// 		left: 40,
				// 		bottom: 30,
				// 		right: 40
				// 	},
				// 	tooltip: {
				// 		trigger: 'axis'
				// 	},
				// 	xAxis: {
				// 		type: 'category',
				// 		data: times[0]
				// 	},
				// 	yAxis: {
				// 		type: 'value'
				// 	},
				// 	series: seriesData
				// };
				chart3.setOption(option, true)
				chart3.resize()
				console.log('任意贴图表3')

				window.addEventListener("resize", () => {
			        chart3.resize();
			    });
			})
		},
		getTypeTj() {
			fetchZdyTJ().then(res => {
				this.mainData = res
			})
		},
		drawChart1(Xdata, Ydata) {
			let chart1 = echarts.init(document.getElementById('echarts1'));
			var option = {
				backgroundColor: "#fff",
				grid: {
					top: 40,
					left: 40,
					bottom: 30
					// containLabel: true
				},
				legend: {
					show: false,
					x: 'center',
					y: '5',
					itemWidth: 10,
					itemHeight: 10,
					textStyle: {
						color: '#1bb4f6'
					},
					data: ['近10天扫码量'],
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				xAxis: [{
					type: 'category',
					// name: '地区',
					boundaryGap: true,
					// data: ['03-16', '03-17', '03-18','03-19','03-20','03-21','03-22'],
					data: Xdata,
					"axisLabel": {
						"color": "#454C54", //X轴文字
						"fontSize": 14,
						"interval": 0,
						"font-family": "SourceHanSansCN-Regular, SourceHanSansCN",
					},
					axisPointer: {
						type: 'shadow'
					},
					axisLine: {
						show: false,
						color: '#c0c3cd'
					},
					splitLine: {
						show: false, // X轴线 颜色类型的修改
						lineStyle: {
							type: 'dashed',
							color: '#292c38'
						}
					}
				}],
				yAxis: [{
					type: 'value',
					axisLabel: {
						formatter: '{value}',
						color: "#454C54", //y轴文字
						fontSize: 16,
						interval: 0,
						"font-family": "SourceHanSansCN-Regular, SourceHanSansCN",
					},
					axisLine: {
						show: false
					},
					axisTick: {
						show: false
					}
				}],
				series: [{
					name: "扫码量",
					type: "bar",
					smooth: true,
					showSymbol: true,
					// barWidth: 15, // 柱子宽度
					symbolSize: 1,
					zlevel: 3,
					color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
						offset: 0,
						color: '#11d56d'
					},
					{
						offset: 1,
						color: '#83bff6'
					}
					]),
					barWidth: '14px',
					itemStyle: {
						normal: {
							//颜色渐变
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: '#5DE1F3'
							}, {
								offset: 1,
								color: '#3387FF'
							}]),
							barBorderRadius: [20]
						},

					},
					lineStyle: {
						normal: {
							width: 2,
							color: "#EDA01D",
						}
					},
					// data: [30,28,50,48,50,36,58]
					data: Ydata,
				},]
			};
			chart1.setOption(option)
			chart1.resize()
			// window.resize(function () { // 重置容器高宽
			//
			// })
		},
		showDetail(flag) {
			this.flag = flag
      this.pageNum = 1
			this.getDetailData()
		},
		handleCurrentChange(val) {
			this.pageNum = val
			this.getDetailData()
		},
		handleCurrentChange2(val) {
			this.topPageNum = val
			this.showTopDetailDialog()
		},
		stateHandleCurrentChange(val) {
			this.statePageNum = val
			this.getStateDetail()
		},
		getDetailData() {
			let params = {
				flag: this.flag,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
			}
			fetchZdyTjDetail(params).then(res => {
				this.detailData = res.list
				this.total = res.total
				if (!this.dialogVisible) {
					this.dialogVisible = true
				}
			})
		}

	}
}
</script>
<style>
.el-divider--vertical {
	display: inline-block;
	width: 1px;
	height: 9em;
	margin: 0 8px;
	vertical-align: middle;
	position: relative;
	left: 22px;
	float: left;
}

.scanNumRow {
	width: 28px;
	height: 21px;
	font-size: 18px;
	font-family: DINAlternate-Bold, DINAlternate;
	font-weight: bold;
	color: #FF8F00;
	line-height: 21px;
}

.circle {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: #fff;
}

.circleWrap {
	width: 28px;
	height: 28px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 2px;
	border-radius: 50%;
	border: 2px;
}

.topWrap tr td:first-child {
	border-bottom-left-radius: 12px;
}

.topWrap tr td:last-child {
	border-bottom-right-radius: 12px;
}

.topWrap tr td:first-child {
	border-top-left-radius: 12px;
}

.topWrap tr td:last-child {
	border-top-right-radius: 12px;
}

.oshurow td {
	border: none !important;
}

.topWrap .el-table::before {
	height: 0;
}

.topWrap .is-leaf {
	border: none !important;
}

.el-table .jishurow {
	width: 100%;
	height: 46px;
	background-color: #F5F8FB;
	font-size: 16px;
	font-family: SourceHanSansCN-Regular, SourceHanSansCN;
	font-weight: 400;
	color: #425063;
}

.el-table .oshurow {
	background: #FFFFFF;
	border-radius: 8px;
	height: 46px;
	font-size: 16px;
	font-family: SourceHanSansCN-Regular, SourceHanSansCN;
	font-weight: 400;
	color: #425063;
	line-height: 24px;
	border-color: #FFFFFF !important;

}

.topTitle {
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #454C54;
	line-height: 27px;
}

.newsTitle {
	margin-top: 23px;
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #454C54;
	line-height: 27px;
}

.topBoxContent {
	width: 80%;
	position: relative;
	left: 70px;
	top: 32px;
}

.topBoxNum {
	font-size: 34px;
	font-family: DINAlternate-Bold, DINAlternate;
	font-weight: bold;
	color: #4E4E4E;
	line-height: 40px;
}

.topBoxLabel {
	font-size: 14px;
	font-family: SourceHanSansCN-Regular, SourceHanSansCN;
	font-weight: 400;
	color: #4E4E4E;
	line-height: 21px;
	margin-bottom: 10px;
}

.topBox {
	width: 100%;
	background: #F7F9FA;
	//display: flex;
	flex-direction: row;
  margin-bottom: 10px;
}
.topBox2{
	border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
}

.topBoxItem {
	float: left;
	width: 370px;
	height: 122px;
	background: #FFFFFF;
	border-radius: 8px;
	margin-top: 27px;
	margin-bottom: 20px;
	margin-left: 20px;
	cursor: pointer;
	flex: 1;
	border: 1px solid #e0e0e0;
}

.topBoxImage {
	display: inline-block;
	position: relative;
	top: 23px;
	left: 36px;
	float: left;
}

.modelChoose {

}

.stateDiv {
	cursor: pointer;
	border: 1px solid #e0e0e0;
	border-radius: 5%;
	position: relative;
	display: inline-block;
	width: 116px;
	margin-left:15px;
}

.topWrap {
	color: #333;
	margin: 20px 0;
}

.echarts1 {
	width: 100%;
	height: 300px;
}

.blue {
	color: #3692ff;
}

.green {
	color: #1d9832;
}

.titleBox {
	font-size: 18px;
	font-weight: 500;
	color: #31353d;
	position: relative;
	padding: 14px 0;
	width: fit-content;
	z-index: 1;
}

.titleBox:after {
	content: "";
	display: block;
	width: 80%;
	height: 11px;
	position: absolute;
	z-index: -1;
	right: -5px;
	bottom: 14px;
	background: rgba(197, 212, 255, 0.5);
}

.lengeBox {
	margin: 10px 0;
}

.lengeBox .linesImg {
	width: 30px;
	height: 30px;
	margin-right: 15px;
}

.linesForBox {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	/* font-size: 0.96rem; */
	font-size: 14px;
	padding-right: 30px;
}

.lengeBox .scanShows .newsNumss {
	/* font-size: 1.42rem; */
	font-size: 24px;
	color: #EDA01D;
	line-height: 30px;
	font-weight: 700;
}

.linesBox {
	margin-top: 5px;
	height: 250px;
	/* border: 1px solid #F00; */
}

.scanItem {
	width: 100%;
	height: 300px;
	background-color: #ffffff;
	position: relative;
	border: 1px solid #ccc;
	margin-top: 40px;
}

.dataTipsBox {
	position: absolute;
	left: 0;
	top: 0;
}

.codeItem {
	width: 46%;
	float: left;
	margin-right: 1%;
	margin-left: 1%;
	cursor: pointer;
}

.scanItem .codeItem:hover {
	background-color: #fefbf6;
}

.forLines {
	width: 2px;
	height: 30px;
	border-left: 1px dashed #e8e8e8;
	float: left;
	margin-top: 35px;
}

.codeNumber {
	/* font-size: 1.75rem;
  line-height: 3.2rem; */
	font-size: 25px;
	line-height: 55px;
	text-align: center;
	margin-top: 10px;
}

.codeTitle {
	font-size: 16px;
	color: #4E4E4E;
	/* line-height: 1.8rem; */
	line-height: 10px;
	text-align: center;
	padding: 20px 0;
	font-weight: 800;
}

.disflex {
	display: flex;
}

.wrapBox {
	background: #fff;
	padding: 20px;
	margin-bottom: 20px;
}

.wrapBoxCell {
	width: 45%;
	float: left;
}

.wrapCell {
	justify-content: space-between;
}

.textBtn {
	color: #3692ff;
	cursor: pointer;
	font-size: 18px;
	font-family: DINAlternate-Bold, DINAlternate;
	font-weight: bold;
	color: #FF8F00;
}

.avatar {
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

/* .typeList>div:last-child::after {
	position: absolute;
	content: ' ';
	right: -25px;
	top: 0;
	width: 1px;
	height: 117px;
	background: #e0e0e0;
} */

.filter-item {
	margin-left: 20px
}
.topBox{
	display: flex;
	//justify-content: flex-end;
	background: #fff;
}
.typeWrap{
	display: flex;
	justify-content: flex-start;
}
.typeWrap .topTitle{
	line-height: 35px;
	padding: 10px;
}
.typeList{
	/* border-right: 1px solid #e0e0e0; */
	/* padding-right: 5%; */
}
.splitDiv{
	width: 1px;
	height: 117px;
	background: #e8e8e8;
	margin: 0 4% 0 5%;
	margin-top: 50px;
}
.typeWrap .typeList:last-child{
	/* border: none; */
}
.rytTitIcon{
	display: inline-block;
	vertical-align: middle;
	width: 25px;
	height: 25px;
	margin-right: 5px;
}
.handle{
  cursor: pointer;
}

.conImg{
  width: 50px;
}
.cItem{
  display: flex;
}
</style>

<template>
  <el-dialog
  title="续费提醒"
  :visible.sync="dialogVisible"
  :modal-append-to-body = "false"
  width="30%"
  :before-close="handleClose">
  <div class="scanBody">
    <div class="title">您购买的服务已到期，请续费</div>
    <img :src="codeImg" />
    <div class="tip">打开微信,扫一扫</div>
  </div>
  
</el-dialog>
</template>
<script>
const QR = require('@/utils/weapp-qrcode.js')
export default {
  data() {
      return {
        dialogVisible: false,
        codeImg: '',
      };
  },
  created() {
    let companyId = JSON.parse(localStorage.getItem('info')).companyId
    let codeUrl = `https://api.86122m.com/pay?companyId=${companyId}`
    this.codeImg = QR.drawImg(codeUrl, {
                            typeNumber: 4,
                            errorCorrectLevel: 'M',
                            size: 196 // 创建尺寸，用于下载
                        })
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
    },
    handleClose(done) {
      this.dialogVisible = false
    }
  }
}
</script>
<style scoped>
.scanBody{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.scanBody .title {
  color: #000;
  font-size: 16px;
}
.scanBody .tip{
  color: #737f80;
}
</style>
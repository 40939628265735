<template>
	 <div class="contentBox">
		 <div class="rowBox clearfix" style="background: #fff;padding-bottom: 15px">
			 <div class="scanTimeBox">
			     <div class="ibox">
			         <div class="titForTips">
			             实时数据统计 
									 <!-- {{ hourIndex}} -->
			         </div>
			         <div id="review_box">
			             <div id="news">
			                 <div class="topsBox middle_font">
			                     <div class="topItems top_type" style="width: 8%">类型</div>
			                     <div class="topItems top_add" style="width: 17%">地区</div>
			                     <div class="topItems top_pro" style="width: 16%">产品(姓名)</div>
			                     <div class="topItems top_iden" style="width: 15%">标识</div>
			                     <div class="topItems top_time" style="width: 18%">时间</div>
			                     <div class="topItems " style="width: 24%;">客户</div>
			                 </div>
			                 <ul id="news_ul">
			                     <li class="iden_li" v-for="(item,index) in scanList" :key="index">
			                         <div class="liItems top_type titActive" style="width: 8%" :title="item.type">{{item.type? item.type : '-'}}</div>
			                         <div class="liItems" style="width: 17%;padding-left: 5px;" :title="item.address">
			                             {{item.address? item.address: '-'}}
			                         </div>
			                         <div class="liItems top_pro titActive" style="width: 16%" :title="item.title">{{item.className ? item.className : '-'}}</div>
			                         <div class="liItems top_iden titActive" style="width: 18%;padding-right: 2%" :title="item.handle">
			                             {{item.handle ? item.handle : '-'}}
			                         </div>
			                         <div class="liItems top_time titActive" style="width: 16%;" :title="item.createTime">{{item.createTime ? item.createTime : '-'}}
			                         </div>
			                         <div class="liItems top_time titActive" style="width: 20%;padding-left: 2%" :title="item.nickeName"><span
			                                 class="nickSpanBlock">
			                             <img :src="item.avatarUrl ? item.avatarUrl : defaultAvatar" alt="" class="avatarImg">
			                             {{item.nickeName? item.nickeName: '微信用户'}}</span>
			                             <span class="timeShow" :class="getTimeColor(item.seeTime)" v-if="item.seeTime">
			                                 {{ getTimeText(item.seeTime) ? getTimeText(item.seeTime) : '-' }}
			                             </span>
			                         </div>
			                     </li>
			                 </ul>
			             </div>
			             <div class="linesBlock"></div>
			         </div>
			     </div>
			 </div>
			 <div class="mapShow">
			     <div class="ibox">
			         <div class="ibox-content" style="height: auto;padding: 15px 5px 10px 5px;border-color: #fff">
			             <div ref="myEchart" id="echartsmap2" style="width: 100%;height: 275px;z-index: 19;overflow: visible;"></div>
			         </div>
			     </div>
			 </div>
			 <div class="pieShowBox">
			     <el-button type="primary" class="detailTitle" plain @click="detailsClick()">详情</el-button>
			     <div class="pieBox" id="pieBox2"></div>
			     <span class="pieMore" plain @click="handleRegionMore">更多>></span>
			 </div>
		 </div>
		 <div class="scansBox clearfix">
		     <div class="scanItem scanItem1" :class="{'newsItems':companyMsg.isHaveNfc}">
		         <div class="dataTipsBox">
		             <img src="@/assets/images/scan/icon_smtj2x.png" alt=""
		                  class="tipBackImg">
		             <div class="tipsTitles">扫码统计</div>
		         </div>
		         <div class="codeItem" @click="handleScanDialog(1, scanData,'人次', false)">
		             <div class="codeNumber yell_Color" id="num5">{{scanData.allNum}}</div>
		             <div class="codeTitle">累计人次</div>
		         </div>
		         <div class="forLines"></div>
		         <div class="codeItem" @click="handleScanDialog(1, scanData,'人次', true)">
		             <div class="codeNumber yell_Color" id="num6">{{scanData.todayNum}}</div>
		             <div class="codeTitle">今日扫码累计</div>
		         </div>
		         <div class="codeItem" @click="handleScanDialog(1, scanData,'市区', false)">
		             <div class="codeNumber yell_Color" id="num7">{{scanData.cityNum}}</div>
		             <div class="codeTitle">扫码市区</div>
		         </div>
		         <div class="forLines"></div>
		         <div class="codeItem" @click="handleScanDialog(1, scanData,'市区', true)">
		             <div class="codeNumber yell_Color" id="num8">{{scanData.todayCityNum}}</div>
		             <div class="codeTitle">今日扫码市区</div>
		         </div>
		     </div>
		     <div class="scanItem scanItem2" :class="{'newsItems':companyMsg.isHaveNfc}">
		         <div class="dataTipsBox">
		             <img src="@/assets/images/scan/icon_yztj2x.png" alt=""
		                  class="tipBackImg">
		             <div class="tipsTitles">验证统计</div>
		         </div>
		         <div class="codeItem" @click="handleScanDialog(2, scanData, '人次', false)">
		             <div class="codeNumber gre_color" id="num9">{{checkData.allNum}}</div>
		             <div class="codeTitle">累计人次</div>
		         </div>
		         <div class="forLines"></div>
		         <div class="codeItem" @click="handleScanDialog(2, scanData, '人次', true)">
		             <div class="codeNumber gre_color" id="num10">{{checkData.todayNum}}</div>
		             <div class="codeTitle">今日累计人次</div>
		         </div>
		         <div class="codeItem" @click="handleScanDialog(2, scanData, '市区', false)">
		             <div class="codeNumber gre_color" id="num11">{{checkData.cityNum}}</div>
		             <div class="codeTitle">验证市区</div>
		         </div>
		         <div class="forLines"></div>
		         <div class="codeItem" @click="handleScanDialog(2, scanData, '市区', true)">
		             <div class="codeNumber gre_color" id="num12">{{checkData.todayCityNum}}</div>
		             <div class="codeTitle">今日验证市区</div>
		         </div>
		     </div>
		 </div>
		 <!--窜货异常-->
		 <div class="chMain clearfix">
			 <div class="titForTips">
			     窜货异常
			 </div>
		   <FchTable></FchTable>
		 </div>

		 <div class="scanTimeBox1 borBtm">
			 <div class="titForTips">
			     扫码异常
			 </div>
		     <WaringTable></WaringTable>
		 </div>

		 

		 <detailDialog ref="detailDialog"></detailDialog>
		 <regionMoreDialog ref="regionMoreDialog"></regionMoreDialog>
		 <scanDialog ref="scanDialog"></scanDialog>
		 
	 </div>
</template>

<script>
	import echarts from "echarts";
	import 'echarts/map/js/china.js' // 引入中国地图数据
	import {getTimeScan, scanStaticTotal, fetchLineChartData, getScanAreaData, getTimeMapData, getDealerArea} from "@/api/scanStatics";
	import WaringTable from '../detailDialog/WaringTable'
	import FchTable from '../detailDialog/FchTable'
	import regionMoreDialog from '../detailDialog/regionMoreDialog'
	import detailDialog from "../detailDialog/index"
	import { fetchCompany } from '@/api/companyManage';
	import ScanDialog from "../detailDialog/ScanDialog"
	import {
  CodeToText,
} from 'element-china-area-data'
	export default {
		data() {
			return {
				companyMsg: {},
				scanList: [],
				timer: null,
				scanData: {},
				checkData: {},
				cId:JSON.parse(localStorage.getItem('info')).companyId,
				tplTimes: null,
				mapIntimer: null,
			}
		},
		components: {
			detailDialog,
		    WaringTable,
			regionMoreDialog,
			ScanDialog,
			FchTable
		},
		mounted() {
			this.getCompany()
			this.fetchMapData()
			this.fetchTimeData()
			this.scanAreaData()
			this.fetchScanPeople()
			this.fetchCheckPeople()
			if(this.timer) {
				this.timer = null
			}
			let self = this
			this.timer = setInterval(() => {
				self.fetchTimeData()
			    // self.fetchMapData()
				self.scanAreaData()
				self.fetchScanPeople()
				self.fetchCheckPeople()
			}, 5000)
		},
		methods: {
			destroy() {
				clearInterval(this.timer)
				clearInterval(this.tplTimes)
				clearInterval(this.mapIntimer)
			},
			handleScanDialog(type, data, fetchType, isToday) {
			    this.$refs.scanDialog.openDialog(type, data, fetchType, isToday)
			},
			getCompany(){
			    let params ={
			        myCardId: JSON.parse(localStorage.getItem('info')).cardId
			    }

			    fetchCompany(params)
			        .then(res=>{
			            this.companyMsg = res.data
			            // console.log('companyMsg',this.companyMsg)
			        })
			},
			fetchScanPeople() {
			    let self = this
			    scanStaticTotal({logType: 1,cId:this.cId}).then((res) => {
			        // console.log("res", res);
			        if (res.code == 200) {
			            self.scanData = res.data
			        }
			    })
			},
			fetchCheckPeople() {
			    let self = this
			    scanStaticTotal({logType: 2,cId:this.cId}).then((res) => {
			        // console.log("res", res);
			        if (res.code == 200) {
			            self.checkData = res.data
			        }
			    })
			},
			detailsClick() {
			    this.$refs.detailDialog.showDialog()
			},
			handleRegionMore() {
			    this.$refs.regionMoreDialog.openDialog()
			},
			// 获取浏览时长class颜色
			getTimeColor(time){
			    let colorArr = ['grey','blue','orange']
			    if(time && time < 60){
			        return colorArr[0]
			    }else if(time && time >= 60 && time < 600 ) {
			        return colorArr[1]
			    }else if(time >= 600) {
			        return colorArr[2]
			    }
			},
			getTimeText(time) {
			  if(time && time < 600){
			    return time + 's'
			  }else if(time && time >= 600) {
			    return '600+'
			  }else {
			    return '-'
			  }
			},
			fetchTimeData() {
			    let self = this
			    var nId = sessionStorage.getItem('logId')
			    var data = {
			        num: 5
			    }
			    if (!nId) {

			    } else {
			        if(self.scanList.length == 0) {
			            sessionStorage.removeItem('logId')
			        } else {
			            data.logId = nId
			        }
			    }
			    getTimeScan(data).then((res) => {
			        // console.log("res", res);
			        if (res.code == 200) {
			            if (res.data.length > 0) {
			                if (nId) {
			                    var arrs = res.data
			                    self.scanList = [...arrs, ...self.scanList]
			                    let id = res.data[0].logId
			                    sessionStorage.setItem('logId',id)
			                    self.scanList = self.scanList.slice(0,5)
			                } else {
			                    self.scanList = res.data
			                    let id = res.data[0].logId
			                    sessionStorage.setItem('logId',id)
			                }
			            } else {
			                if(self.scanList.length == 0) {
			                    sessionStorage.removeItem('logId')
			                }
			                // sessionStorage.removeItem('logId')
			            }

			        }
			    })
			},
			//扫码地域分布
			scanAreaData() {
			    let params = {
			        num: 12,
			    }
			    let v=this
			    getScanAreaData(params).then(res => {
			        // console.log('扫码地域分布',res)
			        if (res.code == 200) {
			            var result = res.data
			            var arrs = []
			            result.forEach((item,index) => {
			                if (item.area) {
			                    arrs.push(item)
			                }
			            } )
									// console.log('扫码地域arrs1111',arrs)
			            v.pieEcharts(arrs)
			        }
			    })
			},
			pieEcharts(arrs) {
			    var self = this
					var myPieChart = echarts.init(document.getElementById('pieBox2'));
			    // 定义颜色
			    const colorList = [
			        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
			            {
			                offset: 0,
			                color: "#AD6BFF",
			            },
			            {
			                offset: 1,
			                color: "#FF6F86",
			            },
			        ]),
			        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
			            {
			                offset: 0,
			                color: "#FFBA68",
			            },
			            {
			                offset: 1,
			                color: "#FF8573",
			            },
			        ]),
			        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
			            {
			                offset: 0,
			                color: "#55A6FE",
			            },
			            {
			                offset: 1,
			                color: "#7BCFFF",
			            },
			        ]),
			        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
			            {
			                offset: 0,
			                color: "#42DBFF",
			            },
			            {
			                offset: 1,
			                color: "#89F4FF",
			            },
			        ]),
			        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
			            {
			                offset: 0,
			                color: "#00DEC0",
			            },
			            {
			                offset: 1,
			                color: "#38F7DB",
			            },
			        ]),
			    ];

			    // 定义数据
			    let chartData = arrs.map((item, index) => {
			        return {
			            value: item.num,
			            name: item.area,
			            itemStyle: {
			                borderRadius: 100,
			                color: colorList[index],
			                // shadowColor: '#000',
			                // shadowBlur: 1,
			                // shadowOffsetY: 5,
			                // shadowOffsetX: 0,

			                // borderRadius: 100,
			                // shadowColor: '#2a2a34',
			                // shadowBlur: 5,
			                // shadowOffsetY: 0,
			                // shadowOffsetX: 0,
			                // borderColor: '#2a2a34',
			                // borderWidth: 2,
			            },

			        }
					})
					
					// console.log('chartData1111', chartData)

			    let totalNum = chartData.reduce((per, cur) => per + cur.value, 0)
					// console.log('totalNum1111', totalNum)

			    // 定义options
			    let options={
			        title: [
			                {
			                    text: '扫码',
			                    x: '30%',
			                    y: '43%',
			                    textAlign: 'center',
			                    textStyle: {
			                        fontSize: '18',
			                        fontWeight: '700',
			                        color: '#333333',
			                        textAlign: 'center',
			                        // textShadowColor: '#000',
			                        // textShadowBlur: '1',
			                        // textShadowOffsetX: 2,
			                        // textShadowOffsetY: 2,
			                    },
			                },
			                {
			                    text: '地域分布',
			                    left: '30%',
			                    top: '52%',
			                    textAlign: 'center',
			                    textStyle: {
			                        fontSize: '18',
			                        fontWeight: '700',
			                        color: '#333',
			                        textAlign: 'center',
			                        // textShadowColor: '#000',
			                        // textShadowBlur: '1',
			                        // textShadowOffsetX: 1,
			                        // textShadowOffsetY: 1,
			                    },
			                },
			            ],
			            tooltip: {
			                trigger: 'item'
			            },
			            color: colorList,
			            legend: {
			                orient: 'horizontal', //vertical horizontal
			                right: '1%',
			                top: '25%',
			                // bottom: 0,
			                // left: 0,
			                // show: false,
			                icon: 'circle',
			                width: 100,
			                itemWidth: 10,
			                itemHeight: 10,
			                data: chartData,
			                formatter:function(name){
			                    // var oa = option.series[0].data;
			                    for(var i = 0; i < chartData.length; i++){
			                        if(name==chartData[i].name){
			                            return "{name|" + name + "}" + ' ' + (chartData[i].value/totalNum * 100).toFixed(2) + '%';
			                        }
			                    }
			                },

			                textStyle: {
			                    lineHeight: 15,
			                    rich: {
			                        name: {
			                            fontSize: 12,
			                            fontWeight: "500",
			                            color: "#939EB1",
			                            letterSpacing: '2px',
			                            padding: [0, 0, 0, 0],
			                        },
			                        val: {
			                            fontSize: 12,
			                            fontWeight: "500",
			                            color: "#333333",
			                            letterSpacing: '2px',
			                            padding: [0, 0, 0, 0],
			                        },
			                    },
			                },
			            },
			            series: [{
			                type: 'pie',
			                roundCap: true,
			                radius: ['44%', '51%'],
			                center: ['30%', '50%'],
			                label: {
			                    show: false,
			                },
			                labelLine: {
			                    show: false,
			                },
			                data: chartData,
			            }]
			    }

					myPieChart.setOption(options);
					myPieChart.resize()
			    window.addEventListener("resize", () => {
			        myPieChart.resize();
			    });
			},
			fetchMapData() {
			    var dataList = []
			    /*  [
			          {
			              "pro": "山西省",
			              "number": "7",
			              "time": "1647916496"
			          },
			          {
			              "pro": "北京市",
			              "number": "5",
			              "time": "1647916496"
			          },
			          {
			              "pro": "四川省",
			              "number": "3",
			              "time": "1647916496"
			          }
			      ]*/
			    getDealerArea().then((res) => {
							console.log('窜货地图数据',res);
			        if (res.code == 200) {
									let result = res.data
									console.log('-----res.data--------', res.data)
			            result.forEach((item,index) => {
											item.address = CodeToText[item.province]
											if(item.address.indexOf('北京')>-1) {
													item.address = '北京市'
												}
												if(item.address.indexOf('天津')>-1) {
													item.address = '天津市'
												}

												if(item.address.indexOf('上海')>-1) {
													item.address = '上海市'
												}

												if(item.address.indexOf('重庆')>-1) {
													item.address = '重庆市'
												}

			                var str = {
			                    pro: item.address,
			                    number: null,
													outBoxNum: item.outBoxNum,
													outPackNum: item.outPackNum,
			                    time: item.scanNum
			                }
			                dataList.push(str)
									})
									console.log('result', result)
									console.log('dataList', dataList)

			            setTimeout(() => {
			                if (dataList.length > 0) {
													this.dynamicPro = dataList[0].pro;
			                } else {
			                    this.dynamicPro = ''
											}
											console.log('----this.dynamicPro--------', this.dynamicPro)
			                this.initEchartMap(dataList)
			            }, 100)

			        }
			    })

			},
			stopClick() {
					clearInterval(this.tplTimes)
					clearInterval(this.mapIntimer)
			    this.tplTimes = null
			},
			initEchartMap(arrList) {
			    var self = this
			    this.stopClick()
			    console.log('arrList', arrList);
			    var len = arrList.length
			    if (len == 0) {
			        arrList = []
			        this.tplTimes = null
			    } else {
			        var arrs = []
			        var nLens = arrList.length - 1
			    }

			    if (null != this.tplTimes) {
			        clearInterval(this.tplTimes);
			    }
			    this.tplTimes = null

			    var mapName = 'china'

			    var geoCoordMap = {}

			    var myMap = echarts.init(document.getElementById('echartsmap2'))
					// echarts.registerMap('china', chinaData);
			    myMap.off('click') // 这里很重要！！！
			    /* 获取地图数据 */
			    myMap.showLoading()

			    var mapFeatures = this.$echarts.getMap(mapName).geoJson.features;
			    myMap.hideLoading()

			    console.log('mapFeatures',mapFeatures);

			    mapFeatures.forEach(function (v) {
			        // 地区名称
			        var name = v.properties.name
			        // 地区经纬度
			        geoCoordMap[name] = v.properties.cp
			    })

          console.log('geoCoordMap', geoCoordMap)
			    var pSite = 'top'
			    var cNum = -1
			    var hasClick = false;

			    var nList = []

			    if (arrList.length > 0) {
			        arrList.forEach((item, index) => {
									var str = ''
			            if (item.pro.indexOf('市') != -1) {
			                str = item.pro.replace('市', '')
			            } else if (item.pro.indexOf('省') != -1) {
			                str = item.pro.replace('省', '')
			            } else if( item.pro.indexOf('自治区')!= -1) {
										  str = item.pro.replace('自治区', '')
									}
									console.log('self.dynamicPro', self.dynamicPro)
			            if (item.pro == self.dynamicPro) {
											self.hourIndex = index;
			            }
			            nList.push(str)
			        })
			        console.log('nlist', nList);
			    }

			    var options = {
			        background: '#fff',
			        color: ['#000', '#f7ee21'],
			        tooltip: {
			            triggerOn: 'none', // 关闭默认的mouseover调用
			            trigger: 'item',
			            triggerOn: 'mousemove', // mousemove、click
			            padding: 1,
			            borderWidth: 1,
			            borderColor: '#ccc',
			            backgroundColor: '#c2ddff',
			            position: pSite,
			            formatter: function (p) {
						      		console.log('.......................',p)
			                var times = 0 // 扫码量
			                var nums = 0 // 拼接字符串 出货量
											var newName = p.name
							        var outBoxNum = arrList[self.hourIndex].outBoxNum + '箱'
											var outPackNum = arrList[self.hourIndex].outPackNum ? ('+' + arrList[self.hourIndex].outPackNum + '盒'):''
											console.log('hasClick', hasClick)
			                if (hasClick) {
												 if(arrList.length > 0) {
													 times = arrList[self.hourIndex].time+'次'
			                     nums = outBoxNum + outPackNum
												 }
			                } else {
			                    if (arrList.length > 0) {
			                       times = arrList[self.hourIndex].time+'次'
			                       nums = outBoxNum + outPackNum

			                    }
			                }
			                let context = ''
			                // if (newName == '吉林' || newName == '辽宁' || newName == '黑龙江' || newName == '西藏' ||
			                //     newName == '新疆' || newName == '江苏' || newName == '上海') {
			                //     context = `
											// 			<div style="padding:10px;width:auto">代理商地区: <span style="color: #0a77fb;">${newName}</span></div>
											// 			<div style="width:auto;padding-left: 10px;">出货量: <span style="color: #c58d39;">${nums}</span></div>
											// 			<div style="padding:10px;width:auto">扫码量：<div style="color: #0a77fb;">${times}</div></div>	`
			                // } else {
			                    context = `
														<div style="padding:5px;width:auto">代理商地区：<span style="color: #0a77fb;">${newName}</span></div>
														<div style="padding:5px;width:auto">出货量：<span style="color: #0a77fb;">${nums}</span></div>
														<div style="padding:5px;width:auto">扫码量：<span style="color: #0a77fb;">${times}</span></div>`
											// }
											
			                if (arrList.length > 0) {
			                    hasClick = false
			                    return context
			                }

			            }
			        },
			        geo: {
			            show: true,
			            map: 'china',
			            zoom: 1.1,
			            label: {
			                show: false,

			            },
			            emphasis: {
			                focus: 'self',
			                label: {
			                    show: true,
			                    color: '#fff'
			                },
			                itemStyle: {
			                    color: '#fff'
			                }
			            },
			            roam: false, //地图设置不可拖拽，固定的
			            itemStyle: {
			                areaColor: {
			                    type: 'linear',
			                    x: 0,
			                    y: 0,
			                    x2: 0,
			                    y2: 800,
			                    colorStops: [{
			                        offset: 0,
			                        color: '#0770d5', // 0% 处的颜色
			                    },
			                        {
			                            offset: 0.5,
			                            color: '#f00', // 100% 处的颜色
			                        },
			                        {
			                            offset: 1,
			                            color: '#000', // 100% 处的颜色
			                        }
			                    ],
			                    global: false,
			                },
			                borderColor: '#a3be35',
			                borderWidth: 1,
			                shadowColor: '#b6b6b6',
			                shadowBlur: 20
			            }
			        },
			        series: [{
			            type: 'map',
			            map: 'china',
			            silent: true,
			            zoom: 1.1,
			            geoIndex: 1,
			            aspectScale: 0.75, //长宽比
			            showLegendSymbol: false, // 存在legend时显示
			            label: {
			                show: true,
			                fontSize: 12,
			                color: '#fff',
			                emphasis: {
			                    show: false,
			                    color: '#fff'
			                }
			            },
			            roam: false,
			            itemStyle: {
			                areaColor: {
			                    type: 'linear',
			                    x: 0,
			                    y: 0,
			                    x2: 0,
			                    y2: 800,
			                    colorStops: [{
			                        offset: 0,
			                        color: '#3692ee', // 0% 处的颜色
			                    },
			                        {
			                            offset: 0.5,
			                            color: '#c2d8ef', // 100% 处的颜色
			                        },
			                        {
			                            offset: 1,
			                            color: '#000', // 100% 处的颜色
			                        }
			                    ],
			                    global: true,
			                },
			                borderColor: '#99ffe2',
			                borderWidth: 1,

			            },
			            emphasis: {
			                label: {
			                    show: true,
			                    color: '#000'
			                },
			                itemStyle: {
			                    areaColor: '#99ECF8',
			                    color: "#fff"
			                }
			            }
			        }]
			    }
			    // console.log('arrList是：',arrList.length)
				var currentIndex = -1;
			  var jlIndex = -1
				// // todo
				var self = this
				var dataLen = nList.length;
				// 首次需要执行一次开始
				let fName = nList[0]
				let fIndex = null
				// Object.keys(geoCoordMap).forEach((key,index) => {
				// 			if(key === fName) {
				// 				self.hourIndex = 0
				// 				fIndex = index
				// 				console.log('===============++++++++==', fIndex)
				// 				myMap.dispatchAction({
				// 						type: 'highlight',
				// 						seriesIndex: 0,
				// 						dataIndex: fIndex,
				// 				});
				// 				myMap.dispatchAction({
				// 						type: 'showTip',
				// 						seriesIndex: 0,
				// 						dataIndex: fIndex
				// 				});

				// 			}
				// })
				// 首次需要执行一次结束
				if(self.mapIntimer) {
					clearInterval(self.mapIntimer)
				}
				self.mapIntimer = setInterval(() => {
					  if(self.hourIndex >= dataLen-1) {
							self.hourIndex = 0
						}else {
							self.hourIndex+=1
						}
            console.log('nList', nList)
						let cNmae = nList[self.hourIndex]
						console.log('===========cNmae===========', cNmae)
						// console.log('===========geoCoordMap===========', Object.keys(geoCoordMap))
						Object.keys(geoCoordMap).forEach((key,index) => {
							// console.log('key',key)
							if(key === cNmae) {
								currentIndex = index
								if(jlIndex !== -1) {
								  console.log('-------旧jlIndex------', jlIndex)
										myMap.dispatchAction({
												type: 'downplay',
												// seriesIndex: 0,
												// dataIndex: jlIndex
										});
								}
							  	jlIndex = currentIndex
							}
						})
						console.log('-------当前currentIndex------', currentIndex)
						console.log('-------新jlIndex------', jlIndex)
						
				    myMap.dispatchAction({
				        type: 'highlight',
				        seriesIndex: 0,
				        dataIndex: currentIndex,
						});
				    myMap.dispatchAction({
				        type: 'showTip',
				        seriesIndex: 0,
				        dataIndex: currentIndex
				    });
				    // console.log(currentIndex)
				}, 5000)
			    myMap.setOption(options)
					myMap.resize()
					// 鼠标点击
			    // myMap.on('click', function (e) {
					// 	  console.log('e', e)
			    //     if (arrList.length > 0) {
			    //         hasClick = true
			    //         if (null != self.tplTimes) {
			    //             clearInterval(self.tplTimes);
			    //             self.tplTimes = null
			    //         }
			    //         cNum = e.dataIndex
			    //         // console.log('省份下标是：' + cNum)
			    //         myMap.dispatchAction({
			    //             type: "downplay",
			    //             seriesIndex: 0,
			    //         });
			    //         myMap.dispatchAction({
			    //             type: "unselect",
			    //             seriesIndex: 0,
			    //             dataIndex: 0
			    //         });

			    //         /* myMap.dispatchAction({
			    //             type: "highlight",
			    //             seriesIndex: 0,
			    //             dataIndex: e.dataIndex
			    //         }); */
			    //         myMap.dispatchAction({
			    //             type: "showTip",
			    //             seriesIndex: 0,
			    //             dataIndex: e.dataIndex
			    //         });

			    //         if (self.tplTimes == null && hasClick == false) {
			    //             // hourIndex = 0
			    //             setTimeout(() => {
			    //                 self.tplTimes = setInterval(function () {

			    //                     hasClick = false
			    //                     if (cNum == '-1') {

			    //                     } else {
			    //                         myMap.dispatchAction({
			    //                             type: "unselect",
			    //                             seriesIndex: 0,
			    //                             dataIndex: cNum
			    //                         });
			    //                         cNum = -1
			    //                     }

			    //                     myMap.dispatchAction({
			    //                         type: "downplay",
			    //                         seriesIndex: 0,
			    //                     });
			    //                     myMap.dispatchAction({
			    //                         type: "highlight",
			    //                         seriesIndex: 0,
			    //                         name: nList[self.hourIndex]
			    //                         // dataIndex: hourIndex
			    //                     });
			    //                     myMap.dispatchAction({
			    //                         type: "showTip",
			    //                         seriesIndex: 0,
			    //                         name: nList[self.hourIndex]
			    //                         // dataIndex: hourIndex
			    //                     });
			    //                     self.hourIndex++;
			    //                     if (self.hourIndex > nList.length - 1) {
			    //                         self.hourIndex = 0;
			    //                     }
			    //                 }, 10000);
			    //             }, 2000)
			    //         } else {
			    //             clearInterval(self.tplTimes)
			    //         }

			    //     }
			    // })

			},
		}
	}
</script>

<style scoped>
	.contentBox {
	    width: 100%;
	    height: auto;
	    /* background-color: #fff; */
		margin-bottom: 20px;
	}

	.padLeft15 {
	    padding-left: 15px;
	}

	.rowBox {
	    width: 98%;
	    height: auto;
	    margin: auto;
	}

	.scansBox {
	    width: 100%;
	    height: auto;
	    background-color: #f6f6f6;
	    margin: 5px auto;
	    border-top: 1px solid #eee;
	    /* border-bottom: 1px solid #eee; */
	    padding: 20px 0;
	    display: flex;
	    justify-content: space-between;
		align-items: center;
	}

	.scanTimeBox {
	    width: 45%;
	    height: auto;
	    float: left;
	}

	.scanTimeBox1 {
		width: 100%;
		height: auto;
		margin: auto;
		padding: 10px;
		box-sizing: border-box;
		background: #fff;
	}

	.mapShow {
	    width: 27%;
	    height: 320px;
	    float: left;
	    background-color: #fff;
	}
	.pieShowBox{
	    width: 28%;
	    height: 320px;
	    float: left;
	    background-color: #fff;
	    position: relative;
	}
	.pieBox{
	    width: 100%;
	    height: 300px;
	    position: relative;
			top: 30px;
	}
	.pieTitle{
	    text-align: center;
	    position: absolute;
	    font-weight: bold;
	    left: 15%;
	    top: 42%;
	}
	.pieTitle span {
	    display: block;
	    width: 100%;
	}
	.detailTitle{
	    font-size: 16px;
	    color: #20a0ff;
	    float: right;
	    margin-top: 30px;
	    position: relative;
	    z-index: 20;
	    margin-right: 1%;
	    /* margin-right: 30px; */
	}

	.pieMore{
	    font-size: 16px;
	    color: #20a0ff;
	    float: right;
	    position: relative;
	    z-index: 20;
	    top: 30px;
	    cursor: pointer;
	    margin-right: 3%;
	}

	.ibox {
	    clear: both;
	    margin-bottom: 20px;
	    margin-top: 0;
	    padding: 0;
	    background: #fff;
	    height: auto;
	    position: relative;
	}

	.titForTips {
	    font-size: 18px;
	    height: 40px;
	    line-height: 30px;
	    padding-top: 9px;
	    position: relative;
	    z-index: 2;
		margin-bottom: 20px;
	}
	.titForTips::after{
	    content: "";
	    display: block;
	    width: 80px;
	    height: 11px;
	    background: rgba(226, 234, 255, 0.5);
	    position: absolute;
	    z-index: -1;
	    left: 25px;
	    bottom: 0;
	}

	.topsBox {
	    width: 98%;
	    height: 40px;
	    margin: 5px auto;
	    background-color: #F3F6FA;
	    border-radius: 30px;
	}

	.topItems {
	    float: left;
	    line-height: 40px;
	    color: #000;
	    text-align: center;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    font-size: 15px;
	    line-height: 40px;
	}

	.liItems {
	    float: left;
	    /* height: 3rem;
	    line-height: 3rem; */
	    color: #000;
	    text-align: center;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    position: relative;
	}

	.top_type {
	    width: 10%;
	}

	.top_add {
	    width: 27%;
	}

	.top_pro {
	    width: 18%;
			margin-right: 10px;
	}

	.top_iden {
	    width: 20%;
	}

	.top_time {
	    width: 21%;
	}

	.avatarImg {
	    width: 28px;
	    height: 28px;
	    border-radius: 50%;
	    vertical-align: middle;
	}

	.timeShow {
	    font-size: 12px;
	    background-color: transparent;
	    color: #fff;
	    border-radius: 4px;
	    height: 25px;
	    padding: 0 6px;
	    line-height: 25px;
	    position: absolute;
	    right: 5px;
	    top: 0;
	    bottom: 0;
	    margin: auto;
	}
	.timeShow.grey{
	    background-color: #EAEFFF;
	    color: #4A7AF0;
	}
	.timeShow.blue{
	    background-color: #8EABFF;
	    color: #fff;
	}
	 .timeShow.orange{
	    background-color: #FFAB52;
	    color: #fff;
	}
	ul, li {
	    list-style: none;
	}

	#news_ul {
	    width: 97%;
	    margin: 5px auto;
	}

	ul .iden_li {
	    /* height: 3rem;
	    line-height: 3rem; */
	    height: 50px;
	    line-height: 50px;
	    /* margin-bottom: 5px; */
	    border-bottom: 1px solid #eee;
	    font-size: 13px;
	}

	.scanItem {
	    width: 50%;
	    background-color: #ffffff;
	    float: left;
	    position: relative;
	    padding-top: 35px;
	    margin-top: 0;
	    height: auto;
	    padding-bottom: 20rpx;
	}
	.newsItems{
	    width: 48%;
	}

	.dataTipsBox {
	    position: absolute;
	    left: 0;
	    top: 0;
	}

	.tipBackImg {
	    /* width: 7rem;
	    height: auto; */
	    width: 112px;
	    height: 30px;
	    display: block;
	    float: left;
	}

	.tipsTitles {
	    position: absolute;
	    /* left: 1.3rem;
	    top: 0.26rem; */
	    left: 20px;
	    top: 3px;
	    color: #fff;
	}

	.codeItem {
	    width: 46%;
	    height: 90px;
	    float: left;
	    margin-right: 1%;
	    margin-left: 1%;
	    cursor: pointer;
	}

	.scanItem1 {
		margin-right: 10px
	}
	.scanItem2 {
		margin-left: 10px
	}
	.scanItem1 .codeItem:hover,  .scanItem4 .codeItem:hover {
	    background-color: #fefbf6;
	}
	.scanItem2 .codeItem:hover {
	    background-color: #f5faf4;
	}
	.scanItem3 .codeItem:hover {
	    background-color: #f5f9fb;
	}

	.forLines {
	    width: 2px;
	    height: 30px;
	    border-left: 1px dashed #e8e8e8;
	    float: left;
	    margin-top: 35px;
	}

	.codeNumber {
	    /* font-size: 1.75rem;
	    line-height: 3.2rem; */
	    font-size: 25px;
	    line-height: 35px;
	    text-align: center;
	    margin-top: 5px;
	}

	.codeTitle {
	    font-size: 16px;
	    color: #aaa;
	    /* line-height: 1.8rem; */
	    line-height: 25px;
	    text-align: center;
	}

	.showActives {
	    color: #4C95F8;
	}

	.yell_Color {
	    color: #FF8438;
	}

	.gre_color {
	    color: #08A89B;
	}

	.nfc_color {
	    color: #05c944;
	}
	.echartBox{
	    /* border: 1px solid #f00; */
	    display: flex;
	    flex-direction: row;
	    justify-content: space-between;
	    overflow: hidden;
	    padding: 0 0 30px;
	    background-color: #fff;
	}
	.echartWrap{
	    width: 49%;
	    /* border: 1px solid #0f0; */
	    background: #fff;
	    margin-top: 20px;
	    display: border-box;
	    padding-left: 10px;
	    padding-bottom: 15px;
	    overflow: hidden;
	}
	.lengeBox{
	    display: flex;
	    flex-direction: row;
	    justify-content: flex-start;
	    align-items: center;
	    padding-left: 30px;
	    padding-top: 20px;
	}
	.lengeBox .linesImg{
	    width: 50px;
	    height: 50px;
	    margin-right: 15px;
	}
	.linesForBox{
	    display: flex;
	    flex-direction: row;
	    justify-content: flex-start;
	    align-items: center;
	    /* font-size: 0.96rem; */
	    font-size: 14px;
	    padding-right: 30px;
	}
	.lengeBox .scanShows .newsNumss{
	    /* font-size: 1.42rem; */
	    font-size: 24px;
	    color: #EDA01D;
	    line-height: 30px;
	    font-weight: 700;
	}
	.linesBox{
	    margin-top: 5px;
	    height: 250px;
	    /* border: 1px solid #F00; */
	}
	@media screen and (max-width: 1180px){
	    .scanTimeBox {
	        width: 100%;
	        height: auto;
	        float: left;
	    }

	    .mapShow {
	        width: 50%;
	        height: 320px;
	        float: left;
	        background-color: #fff;
	    }
	    #echartsmap{
	        margin-top: 15px !important;
	    }
	    .pieShowBox{
	        width: 47%;
	        height: 320px;
	        float: left;
	        background-color: #fff;
	        position: relative;
	    }
	    .codeTitle{
	        font-size: 12px;
	    }
	    .newsTitle{
	        font-size: 12px;
	    }
	}
	#echartsmap2{
		position: relative;
		top: 30px;
	}

	.chMain{
		margin-top: 20px;
		margin-bottom: 20px;

		box-sizing: border-box;
		padding: 10px;
		background: #fff;
	}

</style>

<template>
    <div>
        <el-dialog width="60%" :title="type == 1 ? '入库记录' : '出库记录'" :visible.sync="outerVisible" :append-to-body="true">
            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :currentPage="pageNum" :total="total">
                <template v-slot:table>
                    <el-table-column align="center" prop="lotNumber" label="批次号">
                    </el-table-column>
                    <el-table-column align="center" prop="nickName" :label="type == 1 ? '入库员' : '出库员'">
                    </el-table-column>
                    <el-table-column align="center" :label="type == 1 ?'入库量' : '出库量'">
                        <template slot-scope="scope">
                            <span>{{ scope.row.boxNum ? scope.row.boxNum : 0 }}箱</span>
                            <span v-if="scope.row.packNum">+{{ scope.row.packNum }}盒</span>
                        </template>
                    </el-table-column>

                    <template v-if="type == 1">
                        <el-table-column align="center" prop="warehouseName" label="库房">
                       </el-table-column>
                    </template>
                    <template v-else>
                      <el-table-column align="center" prop="dealerName" label="代理商">
                       </el-table-column>
                    </template>

                    <el-table-column align="center" prop="createTime" :label="type == 1 ? '入库时间' : '出库时间'">
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="160">
                        <template slot-scope="scope">
                            <span class="lookItem" @click="lookItem(scope.row)">查看</span>
                        </template>
                    </el-table-column>
                </template>

            </commonTable>
            <el-dialog width="60%" :title="type == 1 ? '入库详情' : '出库详情'" :visible.sync="innerVisible" append-to-body>
                <commonTable :tableData="tableData1" :loading="loading1" @handleSizeChange="handleSizeChange1"
                    @handleCurrentChange="handleCurrentChange1" :currentPage="pageNum1" :total="total1">
                    <template v-slot:table>
                        <!-- <el-table-column align="center" prop="name" label="名称">
                        </el-table-column> -->
                        <el-table-column align="center" label="产品类别">
                            <template slot-scope="scope">
                                <span>{{ scope.row.className }}</span>
                                <span :class="scope.row.type == 3 ? 'name_status' : 'name_status1'">{{ scope.row.type == 3 ?
                                    '箱'
                                    : '盒' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="编码">
                            <template slot-scope="scope">
                                <div style="display: flex;align-items: center;justify-content: center;">
                                    <span>{{ scope.row.handle }}</span>
                                    <img class="copyImg" src="../../../../assets/images/fz.png"
                                        @click="copy(scope.row.handle)" />
                                </div>

                            </template>
                        </el-table-column>
                    </template>

                </commonTable>
            </el-dialog>
        </el-dialog>
    </div>
</template>
<script>
import { crkBatchPage, warehouseLog } from "@/api/crk";
import commonTable from "@/components/common/commonTable";
export default {
    data() {
        return {
            outerVisible: false,
            innerVisible: false,
            tableData: [],
            loading: false,
            pageSize: 10,
            pageNum: 1,
            total: 1,
            tableData1: [],
            loading1: false,
            pageSize1: 10,
            pageNum1: 1,
            total1: 1,
            type: null,
            batchId: null
        }
    },
    components: {
        commonTable
    },
    methods: {
        copy(context) {
            // 创建输入框元素
            let oInput = document.createElement('input');
            // 将想要复制的值
            oInput.value = context;
            // 页面底部追加输入框
            document.body.appendChild(oInput);
            // 选中输入框
            oInput.select();
            // 执行浏览器复制命令
            document.execCommand('Copy');
            // 弹出复制成功信息
            this.$message.success('复制成功');
            // 复制后移除输入框
            oInput.remove();
        },
        closeDialog() {
            this.scanDialogVisible = false
        },
        handleSizeChange(num) {
            this.pageSize = num;
            this.pageNum = 1;
            this.getData()
        },
        handleCurrentChange(num) {
            this.pageNum = num
            this.getData()
        },
        openDialog(type) {
            this.outerVisible = true
            this.type = type
            this.pageNum = 1
            this.getData()
        },
        getData() {
            crkBatchPage({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                wareStatus: this.type
            }).then(res => {
                if (res.code == 200 && res.data.list.length) {
                    this.tableData = res.data.list
                    console.log(this.tableData)
                    this.total = res.data.total
                }else {
                    this.tableData = []
                }
            })
        },
        handleSizeChange1(num) {
            this.pageSize1 = num;
            this.pageNum1 = 1;
            this.lookItem()
        },
        handleCurrentChange1(num) {
            this.pageNum1 = num
            this.lookItem()
        },
        lookItem(item) {
            this.innerVisible = true
            if(item) {
              this.pageNum1 = 1
              this.batchId = item.id
            }
            warehouseLog({
                pageNum: this.pageNum1,
                pageSize: this.pageSize1,
                wareStatus: this.type,
                batchId: this.batchId
            }).then(res => {
                if (res.code == 200 && res.data.list.length) {
                    this.tableData1 = res.data.list
                  this.tableData1.forEach(item=>{

                  })
                    this.total1 = res.data.total
                }else {
                    this.tableData1 = []
                }
            })
        }
    }
}
</script>
<style scoped>
.lookItem {
    color: #409eff;
    cursor: pointer;
}

.name_status {
    display: inline-block;
    width: 26px;
    height: 17px;
    line-height: 17px;
    background: rgba(63, 114, 246, 0.11);
    border: 1px solid #3F72F6;
    border-radius: 4px;
    font-size: 24rpx;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3F72F6;
    text-align: center;
    margin-left: 10px;
}

.name_status1 {
    display: inline-block;
    width: 26px;
    height: 17px;
    line-height: 17px;
    background: rgba(243, 136, 46, 0.11);
    border: 1px solid #F3882E;
    border-radius: 4px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #F3882E;
    text-align: center;
    margin-left: 10px;
}

.copyImg {
    margin-left: 10px;
    cursor: pointer;
}
</style>

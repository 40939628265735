<template>
	<div>
		<el-dialog title="详情" v-loading="pdfLoading" :modal-append-to-body="false" :visible.sync="dialogDetailsVisible"
			:close-on-click-modal="false">
			<div class="downTitle" @click="downPdf()" v-show="drawEnd1 && drawEnd2">下载PDF</div>
			<el-row class="rowBox" ref="mapRef" id="echartCanBox">
				<el-col :span="16">
					<div class="mapBox" id="mapBox"></div>
				</el-col>
				<el-col :span="8">
					<div class="scanBox">
						<div class="titCode">全国扫码用户主要分布情况</div>
						<div class="scanList" id="scansList">

						</div>
						<div class="titCode">区域式分布趋势明显</div>
						<div class="scanList">
							<div class="scanItems">
								<div class="scanCircle"></div>
								<div class="scanAdr" id="titleOne">扫码城市主要以山西辐射周边地市为主</div>
							</div>
							<div class="scanItems">
								<div class="scanCircle"></div>
								<div class="scanAdr" id="titleTwo">广东广西等省份扫码意愿较全国其他省份强烈</div>
							</div>
							<div class="scanItems">
								<div class="scanCircle"></div>
								<div class="scanAdr" id="titleThree">形成华中、华南、西南高频率扫码区域</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col>
					<div class="linesBox" id="linesPdfBox" style="page-break-inside:avoid;">
						<div class="showLineWord">扫码数据统计(人次)</div>
						<div id="lineShowBox"></div>
					</div>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
	import html2canvas from 'html2canvas'
	import JsPDF from 'jspdf'
	import echarts from "echarts";
	import 'echarts/map/js/china.js' // 引入中国地图数据
	import $ from 'jquery'
	import {
		versionTimes,
		SortByProps
	} from '@/utils/utils'
	import {
		getMapData
	} from "@/api/scanStatics";
	export default {
		name: "index",
		data() {
			return {
				pdfLoading: true,
				exportPDFtitle: "扫码地域分布",
				dialogDetailsVisible: false,
				nowsMap: [],
				nameMap: [],
				objMap: {},
				lineDatas: [],
				mapDatas: [],
				reTitle: [],
				pdfList: [],
				timeTimeout: null,
				drawEnd1: false,
				drawEnd2: false,
			}
		},
		methods: {
			downPdf() {
				debugger
				var self = this
				this.pdfLoading = true
				if (self.pdfList.length > 1) {
					self.$nextTick(() => {
						self.exportPdf(this.pdfList)
						self.pdfLoading = false
					})
				} else {
					setTimeout(() => {
						self.ewmDwShow('echartCanBox', 0, 0)
						setTimeout(() => {
							self.ewmDwShow('linesPdfBox', 0, 1)
						}, 500)
					}, 1000)
					setTimeout(() => {
						self.$nextTick(() => {
							self.exportPdf(this.pdfList)
							self.pdfLoading = false
						})
					}, 2500)
				}
			},
			showDialog() {
				var self = this
				this.dialogDetailsVisible = true
				self.$nextTick(() => {
					self.getMapDataList()
					setTimeout(() => {
						self.ewmDwShow('echartCanBox', 0, 0)
						setTimeout(() => {
							self.ewmDwShow('linesPdfBox', 0, 1)
						}, 500)
					}, 1000)
					this.pdfLoading = false
				})

			},
			hiddenDialog() {
				this.dialogDetailsVisible = false
			},
			getMapDataList() {
				var self = this
				getMapData().then((res) => {
					if (res.code == 200) {
						var result = res.data
						var detailData = result.detail

						for (var key in detailData) {
							if (!detailData[key]) {
								delete detailData[key]
							}
						}

						let linesData = []
						result.map.forEach((item) => {
							var strs = {
								name: item.province,
								nums: item.num
							}
							linesData.push(strs)
						})
						self.lineDatas = linesData

						self.showLines()

						var region = []
						self.mapDatas = detailData
						//nowsMap
						var adrName = ''
						for (var key in self.mapDatas) {
							if (!self.mapDatas[key]) {
								return
							}
							if (key == 'db') {
								adrName = '东北'
								region.push(adrName)
							} else if (key == 'hb') {
								adrName = '华北'
								region.push(adrName)
							} else if (key == 'hd') {
								adrName = '华东'
								region.push(adrName)
							} else if (key == 'hn') {
								adrName = '华南'
								region.push(adrName)
							} else if (key == 'hz') {
								adrName = '华中'
								region.push(adrName)
							} else if (key == 'xb') {
								adrName = '西北'
								region.push(adrName)
							} else if (key == 'xn') {
								adrName = '西南'
								region.push(adrName)
							}
							self.mapDatas[key].forEach((items, indexs) => {
								var ns = items.province
								items.name = ''
								if (ns.indexOf('省') != -1 || ns.indexOf('市') != -1) {
									items.name = ns.substr(0, ns.length - 1);
								} else {
									items.name = items.province
								}
								var strs = {
									name: items.province,
									value: items.num
								}
								self.nowsMap.push(strs)
								self.objMap[items.name] = items.num;
								self.nameMap.push(items.name)
							})
						}
						console.log('mapDatas==============', self.mapDatas);
						// self.lineDatas = demoList
						setTimeout(() => {
							self.mapShows()
							self.proviceList()
						}, 100)
						if (region.length > 3) {
							self.reTitle = region.slice(0, 3)
						} else {
							self.reTitle = region
						}


					}
				})
			},
			flatten(arr) {
				var self = this
				return [].concat(...arr.map(x => Array.isArray(x) ? self.flatten(x) : x))
			},
			fetchMapList() {
				var self = this
				var demoList = [{
					"name": "山西省",
					"nums": 6215
				}, {
					"name": "广东省",
					"nums": 625
				}, {
					"name": "北京市",
					"nums": 113
				}, {
					"name": "甘肃省",
					"nums": 82
				}, {
					"name": "上海市",
					"nums": 36
				}, {
					"name": "江苏省",
					"nums": 36
				}, {
					"name": "重庆市",
					"nums": 26
				}, {
					"name": "浙江省",
					"nums": 19
				}, {
					"name": "山东省",
					"nums": 10
				}, {
					"name": "安徽省",
					"nums": 7
				}, {
					"name": "湖南省",
					"nums": 5
				}, {
					"name": "河南省",
					"nums": 4
				}, {
					"name": "江西省",
					"nums": 3
				}, {
					"name": "福建省",
					"nums": 3
				}, {
					"name": "辽宁省",
					"nums": 3
				}, {
					"name": "黑龙江省",
					"nums": 2
				}, {
					"name": "河北省",
					"nums": 2
				}, {
					"name": "云南省",
					"nums": 2
				}, {
					"name": "四川省",
					"nums": 1
				}, {
					"name": "澳门",
					"nums": 1
				}, {
					"name": "天津市",
					"nums": 1
				}, {
					"name": "广西",
					"nums": 1
				}, {
					"name": "新疆",
					"nums": 1
				}]
				var detailData = {
					"hb": [{
						"name": "山西省",
						"nums": 6215
					}, {
						"name": "北京市",
						"nums": 113
					}],
					"db": [{
						"name": "辽宁省",
						"nums": 3
					}, {
						"name": "黑龙江省",
						"nums": 2
					}],
					"hd": [{
						"name": "上海市",
						"nums": 36
					}, {
						"name": "江苏省",
						"nums": 36
					}],
					"hz": [{
						"name": "湖南省",
						"nums": 5
					}, {
						"name": "河南省",
						"nums": 4
					}],
					"hn": [{
						"name": "广东省",
						"nums": 625
					}, {
						"name": "澳门",
						"nums": 1
					}],
					"xn": [{
						"name": "重庆市",
						"nums": 26
					}, {
						"name": "云南省",
						"nums": 2
					}],
					"xb": [{
						"name": "甘肃省",
						"nums": 82
					}, {
						"name": "新疆",
						"nums": 1
					}]
				}
				var region = ["华北", "华南", "华东"]
				self.mapDatas = detailData
				//nowsMap
				for (var key in self.mapDatas) {
					self.mapDatas[key].forEach((items, indexs) => {
						var ns = items.name
						if (ns.indexOf('省') != -1 || ns.indexOf('市') != -1) {
							items.name = ns.substr(0, ns.length - 1);
						} else {
							items.name = items.name
						}
						var strs = {
							name: items.name,
							value: items.nums
						}
						self.nowsMap.push(strs)
						self.objMap[items.name] = items.nums;
						self.nameMap.push(items.name)
					})
				}
				// self.lineDatas = demoList
				setTimeout(() => {
					self.mapShows()
					self.proviceList()
				}, 100)
				self.reTitle = region
			},
			finished(num) {
				if (num == 1) {
					var that = this
					setTimeout(function() {
						that.drawEnd1 = true
					}, 300)
				}
				if (num == 2) {
					var that = this
					setTimeout(function() {
						that.drawEnd2 = true
					}, 300)
				}
			},

			mapShows() {
				var self = this
				var color1 = '#de6e6a'
				var color2 = '#ec8a5d'
				var color3 = '#f2c96b'
				var color4 = '#5a70c0'
				var color5 = '#59a076'
				var color6 = '#85bedb'
				var color7 = '#9eca7f'
				var color8 = '#4099ff'

				// {renderer: 'canvas',devicePixelRatio:1}
				/*    var myChart = echarts.init(document.getElementById('mapBox'), null, {devicePixelRatio: 2.6});*/
				var myChart = echarts.init(document.getElementById('mapBox'))
				myChart.finished = this.finished(1);
				var mapName = 'china'

				// modal.registerMap('china', chinaData);
				myChart.off('click') // 这里很重要！！！
				/* 获取地图数据 */
				myChart.showLoading()


				var geoCoordMap = {};

				/*获取地图数据*/
				myChart.showLoading();
				var mapFeatures = this.$echarts.getMap(mapName).geoJson.features;
				console.log('mapFeatures', mapFeatures)
				myChart.hideLoading();
				mapFeatures.forEach(function(v) {
					// 地区名称
					var name = v.properties.name;
					// 地区经纬度
					geoCoordMap[name] = v.properties.cp;

				});

				console.log(data)

				geoCoordMap = {
					江西: [115.892151, 28.676493],
					河南: [113.665412, 34.757975],
					四川: [104.065735, 30.659462],
					重庆: [106.504962, 29.533155],
					西藏: [91.132212, 29.660361],
					贵州: [106.713478, 26.578343],
					辽宁: [123.429096, 41.796767],
					新疆: [87.617733, 43.792818],
					山东: [117.000923, 36.675807],
					上海: [121.472644, 31.231706],
					澳門: [113.54909, 22.198951],
					山西: [112.549248, 37.857014],
					浙江: [120.153576, 30.287459],
					海南: [110.33119, 20.031971],
					福建: [119.306239, 26.075302],
					青海: [101.778916, 36.623178],
					宁夏: [106.278179, 38.46637],
					湖北: [114.298572, 30.584355],
					甘肃: [103.823557, 36.058039],
					安徽: [117.283042, 31.86119],
					台湾: [121.509062, 25.044332],
					陕西: [108.948024, 34.263161],
					广西: [108.320004, 22.82402],
					天津: [117.190182, 39.125596],
					云南: [102.712251, 25.040609],
					黑龙江: [126.642464, 45.756967],
					广东: [113.280637, 23.125178],
					湖南: [112.982279, 28.19409],
					河北: [114.502461, 38.045474],
					内蒙: [111.670801, 40.818311],
					吉林: [125.3245, 43.886841],
					江苏: [119.767413, 33.041544],
					北京: [116.405285, 39.904989],
					澳门: [113.549090, 22.198951],
					香港: [114.173355, 22.320048]
				};

				var data = self.objMap
				// 保存引导线末端的坐标
				var linesEndCoords = {
					山西: [geoCoordMap['山西'][0], geoCoordMap['山西'][1] + 14],
					陕西: [geoCoordMap['陕西'][0], geoCoordMap['陕西'][1] + 16],
					宁夏: [geoCoordMap['宁夏'][0], geoCoordMap['宁夏'][1] + 9],
					甘肃: [geoCoordMap['甘肃'][0], geoCoordMap['甘肃'][1] + 8],
					河北: [geoCoordMap['河北'][0] + 18, geoCoordMap['河北'][1]],
					山东: [geoCoordMap['山东'][0] + 15, geoCoordMap['山东'][1]],
					河南: [geoCoordMap['河南'][0] + 17, geoCoordMap['河南'][1]],
					安徽: [geoCoordMap['安徽'][0] + 18, geoCoordMap['安徽'][1]],
					湖北: [geoCoordMap['湖北'][0] + 14, geoCoordMap['湖北'][1]],
					江西: [geoCoordMap['江西'][0] + 22, geoCoordMap['江西'][1]],
					福建: [geoCoordMap['福建'][0] + 9, geoCoordMap['福建'][1]],
					海南: [geoCoordMap['海南'][0] + 9, geoCoordMap['海南'][1]],
					重庆: [geoCoordMap['重庆'][0] - 30, geoCoordMap['重庆'][1]],
					云南: [geoCoordMap['云南'][0], geoCoordMap['云南'][1] - 6],
					广西: [geoCoordMap['广西'][0] - 30, geoCoordMap['广西'][1]],
					贵州: [geoCoordMap['贵州'][0], geoCoordMap['贵州'][1] - 13],
					湖南: [geoCoordMap['湖南'][0], geoCoordMap['湖南'][1] - 11],
					内蒙: [geoCoordMap['内蒙'][0], geoCoordMap['内蒙'][1] + 14],
					新疆: [geoCoordMap['新疆'][0] - 15, geoCoordMap['新疆'][1]],
					四川: [geoCoordMap['四川'][0] - 25, geoCoordMap['四川'][1]],
					江苏: [geoCoordMap['江苏'][0] + 15, geoCoordMap['江苏'][1]],
					浙江: [geoCoordMap['浙江'][0] + 17, geoCoordMap['浙江'][1] - 1],
					北京: [geoCoordMap['北京'][0] + 20, geoCoordMap['北京'][1]],
					青海: [geoCoordMap['青海'][0] - 27, geoCoordMap['青海'][1]],
					西藏: [geoCoordMap['西藏'][0], geoCoordMap['西藏'][1] - 10],
					辽宁: [geoCoordMap['辽宁'][0] + 14, geoCoordMap['辽宁'][1]],
					吉林: [geoCoordMap['吉林'][0] + 20, geoCoordMap['吉林'][1]],
					黑龙江: [geoCoordMap['黑龙江'][0] + 10, geoCoordMap['黑龙江'][1]],
					广东: [geoCoordMap['广东'][0] + 24, geoCoordMap['广东'][1]],
					上海: [geoCoordMap['上海'][0] + 17, geoCoordMap['上海'][1] + 1],
					澳门: [geoCoordMap['澳门'][0] + 20, geoCoordMap['澳门'][1]],
				}

				var colorOne = '#92de5b' // 497afd,4ba708
				var colorTwo = '#ecf1f1' // d6ddef
				var colorThree = '#0077fc' // d6ddef


				var nameList1 = '北京市、天津市、山西省、河北省、内蒙古自治区' // 华北地区
				var nameList2 = '黑龙江省、吉林省、辽宁省' // 东北地区
				var nameList3 = '上海市、江苏省、浙江省、安徽省、福建省、江西省、山东省、台湾省' // 华东地区
				var nameList4 = '河南省、湖北省、湖南省' // 华中地区
				var nameList5 = '广东省、广西、海南省、香港特别行政区、澳门特别行政区' // 华南地区
				var nameList6 = '四川省、贵州省、云南省、重庆市、西藏自治区' // 西南地区
				var nameList7 = '陕西省、甘肃省、青海省、宁夏回族自治区、新疆维吾尔自治区' // 西北地区

				// lines引导线数据和坐标
				function dataLines(province, data) {
					var res = [];
					province.forEach(name => {
						if (nameList1.indexOf(name) != -1) {
							res.push({
								name: name,
								value: data[name],
								itemStyle: {
									// areaColor: '#bac40a',
									color: color1
								},
								coords: [
									geoCoordMap[name],
									linesEndCoords[name]
								],
							})
						} else if (nameList2.indexOf(name) != -1) {
							res.push({
								name: name,
								value: data[name],
								itemStyle: {
									// areaColor: '#bac40a',
									color: color2
								},
								coords: [
									geoCoordMap[name],
									linesEndCoords[name]
								],
							})
						} else if (nameList3.indexOf(name) != -1) {
							res.push({
								name: name,
								value: data[name],
								itemStyle: {
									// areaColor: '#bac40a',
									color: color3
								},
								coords: [
									geoCoordMap[name],
									linesEndCoords[name]
								],
							})
						} else if (nameList4.indexOf(name) != -1) {
							res.push({
								name: name,
								value: data[name],
								itemStyle: {
									// areaColor: '#bac40a',
									color: color4
								},
								coords: [
									geoCoordMap[name],
									linesEndCoords[name]
								],
							})
						} else if (nameList5.indexOf(name) != -1) {
							res.push({
								name: name,
								value: data[name],
								itemStyle: {
									// areaColor: '#bac40a',
									color: color5
								},
								coords: [
									geoCoordMap[name],
									linesEndCoords[name]
								],
							})
						} else if (nameList6.indexOf(name) != -1) {
							res.push({
								name: name,
								value: data[name],
								itemStyle: {
									// areaColor: '#bac40a',
									color: color6
								},
								coords: [
									geoCoordMap[name],
									linesEndCoords[name]
								],
							})
						} else if (nameList7.indexOf(name) != -1) {
							res.push({
								name: name,
								value: data[name],
								itemStyle: {
									// areaColor: '#bac40a',
									color: color7
								},
								coords: [
									geoCoordMap[name],
									linesEndCoords[name]
								],
							})
						} else {
							res.push({
								name: name,
								value: data[name],
								itemStyle: {
									// areaColor: '#bac40a',
									color: colorOne
								},
								coords: [
									geoCoordMap[name],
									linesEndCoords[name]
								],
							})
						}

					})
					console.log('res', res);
					return res;
				}

				// 散点图数据，其中value属性值为地理坐标和value拼接
				function dataScatter(province, data) {
					var res = [];
					console.log('geoCoordMap==============', geoCoordMap)
					province.forEach(name => {
						console.log('name:', name);
						// name = name == '广西' ? '广西' : '广西'
						if (name.indexOf('省') != -1 || name.indexOf('市') != -1) {
							name = name.substr(0, name.length - 1);
						}
						res.push({
							name: name,
							itemStyle: {
								areaColor: colorOne,
								// color: '#0bfae6'
							},
							value: [geoCoordMap[name][0], geoCoordMap[name][1], data[name]]
						})
					})

					return res;
				}

				var customSetList = [];
				var proNameList = self.nameMap
				// var proNameList = ['山西', '河北', '山东', '河南', '江西', '广西', '湖南', '新疆', '四川', '黑龙江', '西藏', '广东']
				//  var proNameList = ['山西', '北京', '辽宁', '黑龙江','上海', '江苏', '河南', '湖南', '广东','澳门','重庆', '云南', '甘肃', '新疆']

				customSetList = dataLines(proNameList, data)
				console.log('customSetList', customSetList);

				var option = {
					legend: {
						left: '2%',
						top: '1%'
					},
					tooltip: {
						trigger: 'item', //触发元素
						formatter: function(params) {
							// console.log(params);
							var val = 0
							self.lineDatas.forEach((item) => {
								if (item.name.indexOf(params.name) != -1) {
									val = item.nums
								}
							})
							var str = `<div>扫码人次：</div> <div style="color: #ffffff">${params.name}: ${val}</div> `
							return str
						}
					},
					geo: {
						type: 'map',
						map: 'china',
						zoom: 0.9,
						left: 80,
						label: {
							show: false,
						},
						emphasis: {
							label: {
								show: false,
							},
						},
						regions: [{ // 隐藏南海诸岛
							name: '南海诸岛',
							itemStyle: {
								opacity: 0,
							}
						}],
					},
					series: [{
							type: 'map',
							map: 'china',
							left: 80,
							zoom: 0.9,
							showLegendSymbol: false,
							itemStyle: {
								normal: {
									borderWidth: 1, //边际线大小
									borderColor: '#ccc', //边界线颜色
									areaColor: colorTwo //默认区域颜色
								},
								emphasis: {
									show: true,
									areaColor: colorOne, //鼠标滑过区域颜色
									label: {
										show: true,
										textStyle: {
											color: '#fff'
										}
									}
								}
							},
							label: {
								show: false
							},
							data: customSetList
						},
						{ // 含引导线的省份，用lines实现
							type: 'lines',
							symbol: 'circle',
							// symbol: 'image://https://pic.idshanxi.com/qhsx/market01.png',
							// symbolSize: 15,
							showLegendSymbol: false,
							symbolSize: [2, 1],
							label: {
								show: true,
								// formatter:'{aNums|{a}}',
								formatter: (params) => {
									console.log('params===============', params.data);
									var datas = params.data
									let str = ''
									str = '{aName|' + datas.name + '}' + '{aNums|' + datas.value + '人次+' + '}';
									// if (datas.value > 0) {
									// 	str = '{aName|' + datas.name + '}' + '{aNums|' + datas.value + '人次+' + '}';
									// } else {
									// 	str = ''
									// }
									return str;
								},
								rich: {
									aName: {
										color: '#000000',
										fontSize: 12,
										marginRight: '15px',
										fontWeight: '700',
										padding: [0, 2, 0, 5],
									},
									aNums: {
										color: colorThree,
										fontSize: 13,
										fontWeight: 'bold',
										marginRight: '15px',
										padding: [0, 5, 0, 5],
										lineHeight: 24
									}
								}
							},
							lineStyle: {
								type: 'dotted',
								color: colorThree,
								shadowBlur: 0,
								width: 2,
								zindex: 99
							},
							data: customSetList,
						},
						{
							type: 'scatter',
							coordinateSystem: 'geo',
							data: dataScatter(proNameList, data),
							symbolSize: 18,
							/* symbol: 'path://M35.025,17.608c-5.209-4.786-11.483-2.301-15.303-4.281v-1.482c0-0.41-0.333-0.743-0.743-0.743c-0.411,0-0.743,0.333-0.743,0.743v24.682c-1.855,0.104-3.261,0.59-3.261,1.175c0,0.661,1.793,1.197,4.005,1.197c2.21,0,4.003-0.536,4.003-1.197c0-0.585-1.405-1.071-3.261-1.175V26.151C24.575,24.573,28.408,17.166,35.025,17.608z',*/
							// symbol: 'image://https://pic.idshanxi.com/qhsx/market01.png',
							symbol: 'image://../addons/ewei_shopv2/plugin/diypage/static/images/market03.png',
							symbolRotate: 0,
							symbolOffset: ['-5%', '-40%'],
							label: {
								normal: {
									formatter: '{b}',
									position: 'top',
									show: false,
									textStyle: {
										color: '#000000',
										fontSize: 16
									}

								},
								emphasis: {
									show: false
								}
							},
							itemStyle: {
								normal: {
									color: '#a9bcea'
								}
							}
						}

					],
					animation: false
				};
				myChart.setOption(option);
				/* var picBase64Info = myChart.getDataURL();//获取echarts图的base64编码，为png格式。
				 $("#newSrc").attr('src', picBase64Info)*/
				window.addEventListener("resize", () => {
					myChart.resize();
				});

			},
			proviceList() {
				var self = this
				var color1 = '#de6e6a'
				var color2 = '#ec8a5d'
				var color3 = '#f2c96b'
				var color4 = '#5a70c0'
				var color5 = '#59a076'
				var color6 = '#85bedb'
				var color7 = '#9eca7f'
				var color8 = '#4099ff'
				var str = ''
				var adrName = ''
				// 根据num属性2，数据从大到小排序
				let arr = self.lineDatas.sort(function(a, b) {
					return SortByProps(a, b, {
						"nums": "descending",
					});
				});
				console.log('-------------', arr)
				var tOne = `扫码城市主要以${arr[0].name}辐射周边地市为主`
				$('#titleOne').html(tOne)
				console.log('lineDatas', self.lineDatas);

				var tit1 = ''
				var tit2 = ''
				if (self.lineDatas[1]) {
					tit1 = arr[1].name
				}
				if (self.lineDatas[2]) {
					tit2 = arr[2].name
				}
				var tTwo = `${tit1}${tit2}等省份扫码意愿较全国其他省份强烈`
				$('#titleTwo').html(tTwo)

				var reWord = self.reTitle.join('、')
				var tThree = `形成${reWord}高频率扫码区域`
				$('#titleThree').html(tThree)

				var scanItems = `
                    width: 100%;
                    height: auto;
                    line-height: 25px;
                    font-size: 16px;
                    text-align: left;
                    position: relative;
                `

				var scanCircle = `
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    position: absolute;
                    left: 0;
                    top: 10px;
                `
				var scanAdr = `
                    padding-left: 15px;
                `

				for (var key in self.mapDatas) {
					var proName = ''
					self.mapDatas[key].forEach((item, index) => {
						proName += item.name + '、'
					})
					proName = proName.substr(0, proName.length - 1);
					// color 2\1\3\5\4\7\6
					if (key == 'db') {
						adrName = '东北地区'
						str += `<div class="scanItems" style="${scanItems}">
                                <div class="scanCircle" style="background-color: ${color2};${scanCircle}"></div>
                                <div class="scanAdr" style="${scanAdr}">${adrName}：${proName}</div>
                            </div>`
					} else if (key == 'hb') {
						adrName = '华北地区'
						str += `<div class="scanItems" style="${scanItems}">
                                <div class="scanCircle" style="background-color:${color1};${scanCircle}"></div>
                                <div class="scanAdr" style="${scanAdr}">${adrName}：${proName}</div>
                            </div>`
					} else if (key == 'hd') {
						adrName = '华东地区'
						str += `<div class="scanItems" style="${scanItems}">
                                <div class="scanCircle" style="background-color: ${color3};${scanCircle}"></div>
                                <div class="scanAdr" style="${scanAdr}">${adrName}：${proName}</div>
                            </div>`
					} else if (key == 'hn') {
						adrName = '华南地区'
						str += `<div class="scanItems" style="${scanItems}">
                                <div class="scanCircle" style="background-color: ${color5};${scanCircle}"></div>
                                <div class="scanAdr" style="${scanAdr}">${adrName}：${proName}</div>
                            </div>`
					} else if (key == 'hz') {
						adrName = '华中地区'
						str += `<div class="scanItems" style="${scanItems}">
                                <div class="scanCircle" style="background-color: ${color4};${scanCircle}"></div>
                                <div class="scanAdr" style="${scanAdr}">${adrName}：${proName}</div>
                            </div>`
					} else if (key == 'xb') {
						adrName = '西北地区'
						str += `<div class="scanItems" style="${scanItems}">
                                <div class="scanCircle" style="background-color: ${color7};${scanCircle}"></div>
                                <div class="scanAdr" style="${scanAdr}">${adrName}：${proName}</div>
                            </div>`
					} else if (key == 'xn') {
						adrName = '西南地区'
						str += `<div class="scanItems" style="${scanItems}">
                                <div class="scanCircle" style="background-color: ${color6};${scanCircle}"></div>
                                <div class="scanAdr" style="${scanAdr}">${adrName}：${proName}</div>
                            </div>`
					}
					$('#scansList').html(str)
				}
			},
			showLines() {
				var self = this
				var myChart = echarts.init(document.getElementById('lineShowBox'), null, {
					renderer: "svg",
				});

				myChart.finished = this.finished(2)

				var xData = []
				var yData = []
				var arrList = []
				if (self.lineDatas.length > 0) {
					arrList = self.lineDatas // .reverse()
				}

				arrList.forEach((item, index) => {
					xData.push(item.name)
					yData.push(item.nums)
				})
				var option = {
					/* title: {
					    text: '扫码数据统计',
					    itemStyle: {
					        textAlign: 'center'
					    }
					},*/
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['扫码量']
					},
					grid: {
						left: '3%',
						right: '4%',
						top: '10%',
						containLabel: true
					},
					/* toolbox: {
					    feature: {
					        saveAsImage: {}
					    }
					},*/
					xAxis: {
						type: 'category',
						boundaryGap: true,
						axisTick: { //x轴刻度线
							"show": false
						},
						axisLabel: {
							rotate: 60
						},
						data: xData
					},
					yAxis: {
						type: 'value',
						axisLine: { //y轴
							"show": false

						},
						axisTick: { //y轴刻度线
							"show": false
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#ccc'],
								width: 2,
								type: 'solid'
							}
						}
					},
					series: [{
						name: '扫码量',
						type: 'line',
						stack: '总量',
						symbol: 'circle',
						smooth: false,
						symbolSize: 1,
						label: {
							show: true,
							position: 'top'
						},
						itemStyle: {
							normal: {
								color: '#009de7',
								lineStyle: {
									color: '#009de7'
								}
							}
						},
						data: yData
					}, ]
				};
				myChart.setOption(option);
				window.addEventListener("resize", () => {
					myChart.resize();
				});

			},
			ewmDwShow(val, val2, val3) {
				var self = this
				var copyDom = $("#" + val)[0]; //要保存的dom
				console.log('copyDom', copyDom);
				var width = copyDom.offsetWidth; //dom宽
				var height = copyDom.offsetHeight; //dom高

				var canvas = document.createElement("canvas"); //创建一个canvas节点
				const scale = 2; //定义任意放大倍数 支持小数
				const scaleCon = 1; //定义任意放大倍数 支持小数
				canvas.width = width * scale; //定义canvas 宽度 * 缩放
				canvas.height = height * scale; //定义canvas高度 *缩放
				var context = canvas.getContext('2d');
				var offsetTop = copyDom.offsetTop;
				// 获得该容器到窗口最左的距离
				var offsetLeft = copyDom.offsetLeft;
				/* context.scale(scaleCon, scaleCon);
				context.translate(-10, -10); //对context的参数修改,translate指的是偏移量*/
				// 【重要】关闭抗锯齿
				context.mozImageSmoothingEnabled = false;
				context.webkitImageSmoothingEnabled = false;
				context.msImageSmoothingEnabled = false;
				context.imageSmoothingEnabled = false;
				var hId = ''
				if (val3 == 0) {
					hId = document.getElementById("echartCanBox")
				} else {
					hId = document.getElementById("linesPdfBox")
				}

				let pageData = ''

				html2canvas(hId, {
					dpi: 300,
					scale: scale,
					scrollY: 0,
					scrollX: 0,
					imageTimeout: 500,
					canvas: canvas, //自定义 canvas
					width: width,
					heigth: height,
					useCORS: true // 【重要】开启跨域配置
				}).then(function(canvas) {
					var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
					// 获取生成的图片的url
					pageData = canvas.toDataURL('image/jpeg', 1.0)
					self.pdfList[val3] = pageData
					if (val2 == 0) {
						if (pageData.length > 1500) {

						} else {
							self.timeTimeout = setInterval(self.times, 1000, pageData, val2);
						}
					} else {
						self.timeTimeout = setInterval(self.times, 1000, pageData, 0);
					}
					console.log('定时器是', val2);
					console.log('self.pdfList', self.pdfList);

				})
			},
			times(param1, param2) {
				console.log(param1);
				if (param1.length > 150) {
					if (param2 == 0) {
						if (null != this.timeTimeout) {
							clearInterval(this.timeTimeout);
							console.log("参数是：", param2)
						}
					}
					// alert('停止')
				}
			},
			exportPdf(pageList) {
				console.log('pageList', pageList);
				let pdf = new JsPDF('l', 'pt', ['1024', '500'])
				for (let [index, item] of pageList.entries()) {
					pdf.addImage(item, 'JPEG', 0, 0, 1000, 480);
					if (index == (pageList.length - 1)) {

					} else {
						pdf.addPage();
					}

				}
				let targetPage = pdf.internal.getNumberOfPages();
				// pdf.deletePage(targetPage); // 删除最后一页
				var times = versionTimes(new Date().getTime())
				var titWords = this.exportPDFtitle + '_' + times + '.pdf'
				pdf.save(titWords);
			}

		},
		beforeDestroy() {
			clearInterval(this.timeTimeout)
			this.timeTimeout = null
		},
		mounted() {

		}
	}
</script>

<style scoped>
	.rowBox {
		width: 100%;
		height: auto;
		position: relative;
	}

	.downTitle {
		font-size: 20px;
		text-align: right;
		width: 100%;
		height: 30px;
		line-height: 30px;
		color: #0a84ff;
		cursor: pointer;
		padding: 0 50px 0 0;
		position: absolute;
		right: 10px;
		top: 85px;
		z-index: 9;
	}

	#mapBox {
		width: 80%;
		height: 475px;
		padding: 5px 15px;
		float: right;
	}

	/deep/.el-dialog {
		width: 70%;
	}

	.scanBox {
		width: 100%;
		height: 500px;
		margin: 0 0 0 0;
		padding: 30px 20px 0;
	}

	.titCode {
		font-size: 18px;
		text-align: left;
		line-height: 50px;
		font-weight: 700;
	}

	.showImgs {
		width: 92%;
		height: auto;
		display: block;
		margin: 5px auto;
	}

	.scanList {
		width: 96%;
		height: auto;
	}

	.scanItems {
		width: 100%;
		height: auto;
		line-height: 25px;
		font-size: 16px;
		text-align: left;
		position: relative;
	}

	.scanCircle {
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 10px;
		background-color: #4099ff;
		position: absolute;
		left: 0;
		top: 10px;
	}

	.scanAdr {
		padding-left: 15px;
	}

	.linesBox {
		width: 100%;
		height: 500px;
	}

	.showLineWord {
		font-size: 20px;
		text-align: center;
		line-height: 50px;
	}

	#lineShowBox {
		width: 88%;
		height: 400px;
		margin: 5px auto 30px;
	}
</style>
<template>
  <div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="scanDialogVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="80%"
        >
        <commonTable
                :tableData="tableData"
                :loading="loading"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :currentPage="pageNum"
                :total="total"
        >
            <template v-slot:table>
                <el-table-column prop="" align="center" label="页面标识" width="">
                    <template slot-scope="scope">
                        <span>{{scope.row.handle ? scope.row.handle : '---'}}</span>
                        <span v-if="scope.row.handle" class="tag-read" :data-clipboard-text="cHandle2"  @click="copyHandle2(scope.row.handle)"><img src="../../../../assets/images/fz.png" /></span>
                    </template>
                </el-table-column>
                <el-table-column prop="city" align="center" label="城市" width="">
                    <template slot-scope="scope">
                        {{ scope.row.city }}
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="数量" width="">
                    <template slot-scope="scope">
                        <span>{{scope.row.count}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="客户" width="">
                    <template slot-scope="scope">
                        <img :src="scope.row.avatarUrl? scope.row.avatarUrl : 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/morentouxiang.png'" class="avatar" />
                        <span>{{scope.row.nickName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="最近购买时间" width="">
                    <template slot-scope="scope">
                        <span>{{scope.row.createTime}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="订单金额" width="">
                    <template slot-scope="scope">
                        <span>{{scope.row.orderPrice ? (scope.row.orderPrice/100).toFixed(2) : '--'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="订单类型" width="">
                    <template slot-scope="scope">
                        <span>{{scope.row.orderType==1 ? '码客通名片' :'码客查'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="订单编号" width="">
                    <template slot-scope="scope">
                        <span>{{scope.row.ordersNo}}</span>
                        <span v-if="scope.row.ordersNo" class="tag-read" :data-clipboard-text="cHandle"  @click="copyHandle(scope.row.ordersNo)"><img src="../../../../assets/images/fz.png" /></span>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
    </el-dialog>
  </div>
</template>

<script>
    import Clipboard from 'clipboard'
    import commonTable from "@/components/common/commonTable";
    import { fetchBuyData } from "@/api/scanStatics"
    export default {
        name: "scanDialog",
        components: {
          commonTable
        },
        data() {
            return {
                scanDialogVisible: false,
                tableData: [],
                loading: false,
                pageSize: 10,
                pageNum: 1,
                total: 1,
                isToday: false,
                dialogTitle: '购买统计',
                cHandle: '',
                cHandle2: '',
            }
        },
        methods: {
         openDialog(isToday) {
           this.pageNum = 1
           this.pageSize = 10
           this.isToday = isToday
           this.getTableData()
           this.scanDialogVisible = true
         },
         closeDialog() {
          this.scanDialogVisible = false
         },
         handleSizeChange(num) {
          this.pageSize = num;
          this.pageNum = 1;
          this.getTableData()
         },
         handleCurrentChange(num){
           this.pageNum = num
           this.getTableData()
         },
         getTableData() {
           let params={
             pageNum: this.pageNum,
             pageSize: this.pageSize,
           }
           if(this.isToday) {
              params.isToday = this.isToday
           }
           fetchBuyData(params).then(res => {
              if(res.data) {
                  this.tableData = res.data.list
                  console.log(this.tableData)
                  this.total = res.list
              }
           })
         },
         // 获取浏览时长class颜色
          getTimeColor(time){
              let colorArr = ['grey','blue','orange']
              if(time && time < 60){
                  return colorArr[0]
              }else if(time && time >= 60 && time < 600 ) {
                  return colorArr[1]
              }else if(time >= 600) {
                  return colorArr[2]
              }
          },
          getTimeText(time) {
            if(time && time < 600){
              return time + 's'
            }else if(time >= 600) {
              return '600+'
            }else {
              return '-'
            }
          },
          // 复制
          copyHandle(data) {
              this.cHandle = data // data.customPageId
              var clipboard = new Clipboard('.tag-read')
              clipboard.on('success', e => {
                console.log('复制成功')
              })
              clipboard.on('error', e => {
                  // 不支持复制
                  console.log('该浏览器不支持复制')
                  // 释放内存
                  // clipboard.listener.destory()
                  this.$message({
                    type: 'warning',
                    message: '该浏览器不支持复制'
                  });
              })
              this.$message({
                type: 'success',
                message: '复制成功'
              });
          },
            copyHandle2(data) {
                this.cHandle2 = data // data.customPageId
                if(!data) {
                  return 
                }
                var clipboard = new Clipboard('.tag-read')
                clipboard.on('success', e => {
                    console.log('复制成功')
                })
                clipboard.on('error', e => {
                    // 不支持复制
                    console.log('该浏览器不支持复制')
                    // 释放内存
                    // clipboard.listener.destory()
                    this.$message({
                        type: 'warning',
                        message: '该浏览器不支持复制'
                    });
                })
            }
        },
        filters: {
        },
        mounted() {


        }
    }
</script>

<style scoped>
.avatar{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}
.timeShowTit {
  font-size: 12px;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  height: 25px;
  padding: 3px 8px;
  line-height: 25px;
  margin: auto;
}
.timeShowTit.grey{
      background-color: #EAEFFF;
      color: #4A7AF0;
  }
.timeShowTit.blue{
      background-color: #8EABFF;
      color: #fff;
  }
.timeShowTit.orange{
      background-color: #FFAB52;
      color: #fff;
  }
.serialNumber{
    /* background-color: #8EABFF; */
    color: #333;
    padding: 0 5px;
    border-radius: 4px;
    height: 25px;
    padding: 3px 8px;
    line-height: 25px;
}
.tag-read{
  cursor: pointer;
  vertical-align: bottom;
  margin-left: 3px;
}
.tag-read img {
  vertical-align: sub;
}
</style>

<template>
  <div style="margin-top: 23px">
    <el-row :gutter="24">
      <el-col :lg="10" style="float:right">
        <el-card class="box-card" shadow="never" body-style="padding:0">
          <div slot="header" class="clearfix">
            <span class="line-left">转化率漏斗</span>
            <!-- <div class="clearfix-right" style="float: right">
              <el-select
                v-model="value"
                placeholder="请选择"
                style="width: 100px"
              >
                <el-option
                  v-for="(item, index) in ['近30天', '近7天']"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div
            style="height: 324px; position: relative"
            class="d-flex justify-content-center mt20"
          >
            <img
              height="300"
              width="80%"
              src="@/assets/images/loudou.png"
              alt=""
            />
            <p class="look position_a">浏览{{ seeGoodsNum }}</p>
            <p class="palce_order position_a">下单{{ orderCount }}</p>
            <p class="pay position_a">付款{{ payCount }}</p>
            <p class="right1 position_a">
              下单转化率 <br />
              {{ seeGoodsNumRate }}
            </p>
            <p class="right2 position_a">
              付款转化率 <br />
              {{ orderCountRate }}
            </p>
            <p class="right3 position_a">
              成交转化率 <br />
              {{ payCountRate }}
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :lg="14" v-if="type == 1" style="padding:0">
        <el-col :lg="12">
          <div class="view pa24" style="height: 182px">
            <span style="color: #737f80; font-size: 20px">订单总额</span>
            <h1 class="bold">￥{{ state.orderAmount | formatMoney }}</h1>
          </div>
        </el-col>
        <el-col :lg="12">
          <div class="view pa24" style="height: 182px">
            <span style="color: #737f80; font-size: 20px">订单数量</span>
            <h1 class="bold">{{ state.orderCount || 0 }}单</h1>
          </div>
        </el-col>
      </el-col>
      <el-col :lg="14" :style="{marginTop:type == 1 && '35px',padding:'0'}">
        <el-col :lg="type == 1 ? 12  : 24" >
          <div
            class="view pa24 d-flex flex-column justify-content-between"
            style="height: 182px; position: relative"
          >
            <span style="color: #737f80; font-size: 20px">业绩目标</span>
            <div>
              <h2 style="color: #454a4c">
                已成交 ￥{{ state.dealMoney | formatMoney }}
              </h2>
              <span style="color: #c0cbcc"
                >月目标 ￥{{ state.targetMoney | formatMoney }}</span
              >
            </div>
            <div id="chartDiv"></div>
          </div>
        </el-col>
        <el-col :lg="type == 1 ? 12  : 24" :style="{marginTop:type == 2 && '35px'}">
          <div
            class="view pa24 d-flex flex-column justify-content-between"
            style="height: 182px; position: relative"
          >
            <span style="color: #737f80; font-size: 20px">业绩差额</span>
            <div class="d-flex">
              <div>
                <h2 style="color: #454a4c">还差 ￥{{ state | dealMoney }}</h2>
                <span style="color: #c0cbcc"
                  >本月已成交{{ state.dealOrderCount }}单</span
                >
              </div>
            </div>
            <div id="chartDiv2"></div>
          </div>
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";

import { getChangeCount } from "@/api/myStatistics";
export default {
  data() {
    return {
      data: [
        { type: "已完成", count: 3, percent:1 },
        { type: "未完成", count: 4, percent:2 }
      ],
      data2: [
        { type: "已完成", count: 0, percent:0 },
        { type: "业绩差额", count: 0, percent:0 }
      ],
      value: "",
      seeGoodsNum: 0,
      orderCount: 0,
      payCount: 0,
      seeGoodsNumRate: "",
      orderCountRate: "",
      payCountRate: "",
    };
  },
  props: {
    state: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  filters: {
    formatMoney(val) {
      return val ? (val / 100).toFixed(2) : 0;
    },
    dealMoney(val) {
      return val.targetMoney <= val.dealMoney
        ? 0
        : (val.targetMoney/100 - val.dealMoney/100).toFixed(2);
    },
  },
  watch: {
    state(val) {
      let arr = JSON.parse(JSON.stringify(val));

      this.data[0].percent = arr.targetMoney ?  +((arr.dealMoney /arr.targetMoney ).toFixed(2)) : 1
      this.data[1].percent = arr.targetMoney ?  +( (arr.targetMoney - arr.dealMoney) /arr.targetMoney ).toFixed(2) : 0
      this.data[0].count = + arr.dealMoney.toFixed(2)
      this.data[1].count = arr.targetMoney ?  +(arr.targetMoney - arr.dealMoney).toFixed(2) : 0


      this.data2[0].percent = arr.targetMoney ?  +(arr.dealMoney /arr.targetMoney ).toFixed(2) : 1
      this.data2[1].percent = arr.targetMoney ?  +( (arr.targetMoney - arr.dealMoney) /arr.targetMoney ).toFixed(2) : 0
      this.data2[0].count = +arr.dealMoney.toFixed(2)
      this.data2[1].count = arr.targetMoney ? +(arr.targetMoney - arr.dealMoney).toFixed(2) : 0


      this.initComponent();
      this.initComponent2();
    },
  },

  created() {
    this.getChangeCount();
  },
   mounted(){
     setTimeout(()=>{

      },1000)
   },
  methods: {
    initComponent() {
      console.log(this.data)
      const chart = new Chart({
        container: "chartDiv",
        autoFit: true,
        height: 220,
        width: 220,
      });

      chart.data(this.data);
      chart.coordinate("theta", {
        radius: 0.6,
        innerRadius: 0.7,
      });
      chart.legend(false);
      chart.tooltip({
        showMarkers: false,
      });
      chart.annotation().text({
        position: ["50%", "50%"],
        content: "完成度",
        style: {
          fontSize: 14,
          fill: "#8c8c8c",
          textAlign: "center",
        },
        offsetY: 0,
      });
      chart.annotation().text({
        position: ["50%", "50%"],
        content:  Math.round(this.data2[0].percent * 100) + "%",
        style: {
          fontSize: 14,
          fill: "#8c8c8c",
          fontWeight: 500,
          textAlign: "center",
        },
        offsetY: 15,
      });
      chart
        .interval()
        .adjust("stack")
        .position("percent")
        .color('type',['#4484FD','#51CDCB'])
        .tooltip("type*percent", ( type, percent) => {
          percent =  Math.round(percent * 100)  + "%";
          return {
            name: type,
            value: percent,
          };

        });
      chart.render();
    },
    initComponent2() {
      const chart = new Chart({
        container: "chartDiv2",
        autoFit: true,
        height: 220,
        width: 220,
      });

      chart.data(this.data2);
      chart.coordinate("theta", {
        radius: 0.6,
        innerRadius: 0.7,
      });
      chart.legend(false);
      chart.tooltip({
        showMarkers: false,
      });
      chart.annotation().text({
        position: ["50%", "50%"],
        content: "业绩差额",
        style: {
          fontSize: 14,
          fill: "#8c8c8c",
          textAlign: "center",
        },
        offsetY: 0,
      });
      chart.annotation().text({
        position: ["50%", "50%"],
        content: Math.round(this.data2[1].percent * 100) + "%",
        style: {
          fontSize: 14,
          fill: "#8c8c8c",
          fontWeight: 500,
          textAlign: "center",
        },
        offsetY: 15,
      });
      chart
        .interval()
        .adjust("stack")
        .position("percent")
        .color('type',['#51CDCB','#4484FD'])
        .tooltip("type*percent", ( type, percent) => {
          percent = Math.round( percent * 100) + "%";
          return {
            name: type,
            value: percent,
          };

        });
      chart.render();
    },
    async getChangeCount() {
      const result = await getChangeCount({
        type: this.type,
        dayNum: this.value,
      });
      const { orderCount, payCount, seeGoodsNum } = result.data;
      this.seeGoodsNum = seeGoodsNum;
      this.orderCount = orderCount;
      this.payCount = payCount;
      this.seeGoodsNumRate = seeGoodsNum
        ? ((orderCount / seeGoodsNum) * 100).toFixed(2) + "%"
        : "0%";
      this.orderCountRate = orderCount
        ? ((payCount / orderCount) * 100).toFixed(2) + "%"
        : "0%";
      this.payCountRate = seeGoodsNum
        ? ((payCount / seeGoodsNum) * 100).toFixed(2) + "%"
        : "0%";
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-size: 48px;
  font-weight: 700;
  color: #51cbcd;
  margin-top: 39px;
}
#chartDiv,
#chartDiv2 {
  position: absolute;
  bottom: -25px;
  right: 30px;
}
.look,
.palce_order,
.pay {
  color: #fff;
  left: 35%;
  font-size: 14px;
}
.position_a {
  position: absolute;
}
.look {
  top: 10%;
}
.palce_order {
  top: 45%;
}
.pay {
  top: 75%;
}
.right1 {
  right: 20%;
  top: 25%;
  color: #67757c;
  font-size: 14px;
}
.right2 {
  top: 55%;
  right: 25%;
  color: #67757c;
  font-size: 14px;
}
.right3 {
  right: 5%;
  top: 50%;
  color: #67757c;
  font-size: 14px;
}

</style>

import { render, staticRenderFns } from "./totalOrder.vue?vue&type=template&id=0c7333ca&scoped=true&"
import script from "./totalOrder.vue?vue&type=script&lang=js&"
export * from "./totalOrder.vue?vue&type=script&lang=js&"
import style0 from "./totalOrder.vue?vue&type=style&index=0&id=0c7333ca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c7333ca",
  null
  
)

export default component.exports
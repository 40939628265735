<template>
  <div>
    <div class="phbAll">
      <el-row class="phbList">
        <div v-for="(item,index) in stateDetailData" :key="index" class="phbItem">
          <div class="topBox phbBlock">
            <div class="topTitle">
              {{item.btnName}}
              <span class="more" @click="lookMore(item)">更多>></span>
            </div>
            <div class="phbBody">
              <el-table :data="item.topVos" style="width: 100%;"
                        :header-cell-style="{ 'text-align': 'center', 'font-size': '14px', 'font-family': 'AppleSystemUIFont', 'color': '#425063', 'line-height': '17px' }"
                        :cell-style="{ 'text-align': 'center', background: 'revert' }">
                <el-table-column prop="" align="center" label="排名" width="100">
                  <template slot-scope="scope">
                    <div class="circleWrap"
                         v-bind:class="[scope.$index + 1 == '1' ? 'index_one' : scope.$index + 1 == '2' ? 'index_two' : scope.$index + 1 == '3' ? 'index_three' : 'index_other']">
                      <div class="circle">
                        0{{ scope.$index + 1 }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" :show-overflow-tooltip="true" prop="pageName" label="名称" />
                <el-table-column align="center" :show-overflow-tooltip="true" prop="num" label="次数">
                  <template slot-scope="scope">
                    <span class="textBtn" @click="numDetail(scope.row,item.btnName)">{{ scope.row.num }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-row>
    </div>

    <!--详情弹窗-->
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="100%"
        >
        <commonTable
                :tableData="tableData"
                :loading="loading"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :currentPage="pageNum"
                :total="total"
        >
          <template slot="table">
            <el-table-column prop="" align="center" label="排名" width="100">
              <template slot-scope="scope">
                <div class="circleWrap"
                     v-bind:class="[scope.$index + 1 == '1' ? 'index_one' : scope.$index + 1 == '2' ? 'index_two' : scope.$index + 1 == '3' ? 'index_three' : 'index_other']">
                  <div class="circle">
                    0{{ scope.row.index }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" :show-overflow-tooltip="true" prop="pageName" label="名称" />
            <el-table-column align="center" :show-overflow-tooltip="true" prop="num" label="次数">
              <template slot-scope="scope">
                <span class="textBtn" @click="numDetail(scope.row,btnName)">{{ scope.row.num }}</span>
              </template>
            </el-table-column>
          </template>
        </commonTable>
    </el-dialog>

<!--    次数详情-->
    <el-dialog
        :title="dialogTitle"
        :visible.sync="btnDialogVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="80%"
    >
      <commonTable
          :tableData="btnNumList"
          :loading="loading"
          @handleSizeChange="btnHandleSizeChange"
          @handleCurrentChange="btnHandleCurrentChange"
          :currentPage="btnPageNum"
          :total="btnTotal"
      >
        <template slot="table">
          <el-table-column :show-overflow-tooltip="true" prop="title" label="名称" align="center"/>
          <el-table-column :show-overflow-tooltip="true" prop="handle" label="标识" align="center">
            <template slot-scope="scope">
              <span class="handle tag-read" :data-clipboard-text="cHandle" @click="copyHandle(scope.row.handle)">{{scope.row.handle}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="二维码" align="center">
            <template slot-scope="scope">
              <vue-qr :size="50" :margin="0" :auto-color="true" :dot-scale="1"
                      :text="`https://api.86122m.com/ryt?ryt=${scope.row.handle}`" class="codeImage"/>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" prop="nowState" label="操作" align="center"/>
          <el-table-column prop="content" label="内容" align="center" width="300px">
            <template slot-scope="scope">
              <div v-for="(item,index) in scope.row.content" :key="index" style="text-aligin: left;word-break:break-all">
                <div v-if="item.type === 1" class="cItem">
                  {{item.title}}: {{item.value}}
                </div>
                <div v-if="item.type === 2" style="">
                  <div style="text-aligin: left;display:flex;">{{item.title}}:</div>
                  <div style="text-aligin: left; display:flex;">
                    <el-image :fit="contain" :src="baseImg+imgItem.src" :preview-src-list="[baseImg+imgItem.src]" v-for="imgItem in item.imgList" :key="imgItem.src" class="conImg"></el-image>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" prop="nickName" label="操作人" align="center"/>
          <el-table-column :show-overflow-tooltip="true" prop="createTime" label="操作时间" align="center"/>
          <el-table-column :show-overflow-tooltip="true" prop="stateName" label="状态" align="center"/>
        </template>
      </commonTable>
    </el-dialog>
  </div>

</template>
<script>
import commonTable from "@/components/common/commonTable";
import {
  getAllBtnTop, fetchDetailMore,getBtnNumDetail
} from '@/api/ryt.js'
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
    commonTable
  },
  data() {
    return {
      state:null,
      stateDetailData:[],
      modelId:null,
      dialogTitle: '排行详情',
      dialogVisible: false,
      tableData: [],
      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      stateSearchParam: {
        startTime: '',
        endTime: ''
      },
      btnNumList:[],
      btnPageNum:1,
      btnPageSize:10,
      btnTotal:0,
      btnDialogVisible:false,
      pageId:null,
      btnName:null,
      cHandle:'',
      baseImg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/',
    }

  },
  created() {
  },
  methods: {
    copyHandle(data) {
      this.cHandle = data // data.customPageId
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {

      })
      clipboard.on('error', e => {
        // 不支持复制
        // 释放内存
        // clipboard.listener.destory()
        this.$message({
          type: 'warning',
          message: '该浏览器不支持复制'
        });
      })
      this.$message({
        type: 'success',
        message: '复制成功'
      });
    },
    numDetail(row,btnName){
      this.pageId = row.pageId
      this.btnName = btnName
      this.btnPageNum = 1
      this.getBtnNumDetail()
      this.btnDialogVisible = true
    },
    workList(arr) {
      let res = []
      arr.forEach(item => {
        if(item.src) {
          res.push(item)
        }
      })
      return res
    },
    getBtnNumDetail(){
      let param = {
        pageId:this.pageId,
        btnName:this.btnName,
        pageNum:this.btnPageNum,
        pageSize:this.btnPageSize
      }
      getBtnNumDetail(param).then(res=>{
        if (res.list){
          this.btnNumList = res.list
          this.btnNumList.forEach(item=>{
            item.content = JSON.parse(item.content)
            if(item.content) {
              for(let i=0; i< item.content.length; i++) {
                if(item.content[i].imgList) {
                  item.content[i].imgList = this.workList(item.content[i].imgList)
                }
              }
            }
          })
          this.btnTotal = res.total
        }
      })
    },
    init(){
      this.getList()
    },
    getList(){
      let params = {
        modelId: this.modelId,
        state: this.state,
        startTime: this.stateSearchParam.startTime,
        endTime: this.stateSearchParam.endTime,
        num:5
      }
      getAllBtnTop(params).then(response => {
        this.stateDetailData = response
      })
    },
    stateHandleSizeChange(num) {
      this.statePageSize = num;
      this.statePageNum = 1;
      this.getList();
    },
    stateHandleCurrentChange(val) {
      this.statePageNum = val
      this.getList()
    },
    lookMore(item) {
      this.btnName = item.btnName
      this.getDetailList()
      this.dialogVisible=true
    },
    getDetailList() {
      let params = {
        modelId: this.modelId,
        startTime: this.stateSearchParam.startTime,
        endTime: this.stateSearchParam.endTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        state: this.btnName,
      }
      fetchDetailMore(params).then(res => {
        if (res.list){
          this.tableData = res.list
          this.tableData.forEach((item,index)=>{
            item.index = (index+1)+(this.pageNum-1)*this.pageSize ;
          })
          this.total = res.total
        }
      })
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getDetailList()
    },
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getDetailList();
    },
    btnHandleCurrentChange(val) {
      this.btnPageNum = val
      this.getBtnNumDetail()
    },
   btnHandleSizeChange(num) {
      this.btnPageSize = num;
      this.btnPageNum = 1;
      this.getBtnNumDetail();
    }
  }
}
</script>
<style scoped>
.myImg {
  width: 80px;
  height: 80px;
}

.avatarImg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}
.topBox{
  width: 24%;
  margin-right: 10px;
  float: left;
}
.phbBlock .topTitle {
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 550;
  line-height: 27px;
  width: 100%;
  background: #bfd4f5;
  box-sizing: border-box;
  padding-left: 10px;
  color: #454a4c;
}
.phbList .phbItem:nth-child(1) .phbBlock .topTitle{
  //background: #98d5fb
}
.phbList .phbItem:nth-child(2) .phbBlock .topTitle{
  //background: #f4d28c
}
.phbList .phbItem:nth-child(3) .phbBlock .topTitle{
  //background: #989afb
}
.phbList .phbItem:nth-child(4) .phbBlock .topTitle{
  //background: #d58cf4
}
.phbBlock{
  flex-direction: column;
  justify-content: flex-start;
  //height: 300px;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.phbBody{
  margin: 0;
  padding: 5px;
}
.phbAll{
  margin: 20px 0;
}
.more{
  float: right;
  padding-right: 10px;
  font-size: 12px;
  color:#454a4c;
  cursor: pointer;
}
.conImg{
  width: 50px;
  flex: 1;
}
.cItem{
  display: flex;
}
</style>

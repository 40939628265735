<!--productAnalysis-->
<template>
    <div class="contentBox">
        <div class="comTit">产品扫码量Top5</div>
        <div class="barChartWrap">
            <barChart></barChart>
        </div>
        <div v-if="address && !address.startsWith('fymp.86122m.com')">
			<div>
			  <div class="comTit" @click="lookSaleMore">
			    产品销量Top5
			  </div>
			  <span class="moreBtn" @click="lookSaleMore">更多>></span>
			</div>
			
			<div class="barChartWrap">
			    <proTableTop></proTableTop>
			</div>
		</div>
        <div v-if="address && !address.startsWith('fymp.86122m.com')">
			<div>
			   <div class="comTit" @click="lookUserMore">
			    用户排名Top5
			   </div>
			   <span class="moreBtn" @click="lookUserMore">更多>></span>
			</div>
			
			<div class="barChartWrap">
			    <userTableTop></userTableTop>
			</div>
		</div>
        <!--销售量更多弹窗-->
        <prodDialog ref="prodDialog"></prodDialog>
    </div>
</template>

<script>
    import barChart from '../detailDialog/barChart'
    import proTableTop from '../detailDialog/proTableTop'
    import userTableTop from '../detailDialog/userTableTop'
    import prodDialog from "../detailDialog/prodDialog";
    export default {
        name: "productAnalysis",
		data() {
			return {
				address: '',
			}
		},
		created() {
			this.address = window.location.host;
		},
        components: {
            barChart,
            proTableTop,
            userTableTop,
            prodDialog,
        },
        methods:{
            lookSaleMore() {
                this.$refs.prodDialog.openDialog(1)
            },
            lookUserMore() {
                this.$refs.prodDialog.openDialog(2)
            }
        }
    }
</script>

<style scoped>
    .contentBox{
        width: 100%;
        background-color: #fff;
        height: auto;
    }
    .comTit{
        display:inline-block;
        font-size: 18px;
        font-weight: 500;
        color: #31353D;
        line-height: 30px;
        padding: 14px 0 14px 16px;
        position: relative;
        z-index: 1;
    }
    .comTit::after{
        content: "";
        display: block;
        width: 75%;
        height: 11px;
        background: rgba(226, 234, 255, 0.5);
        position: absolute;
        z-index: -1;
        right: -5px;
        bottom: 14px;
    }
    .moreBtn{
        font-size: 14px;
        width: 80px;
        color: rgb(81, 203, 205);
        float: right;
        margin-right: 25px;
        line-height: 30px;
        border-radius: 30px;
        text-align: center;
        margin-top: 14px;
        cursor: pointer;
    }
</style>

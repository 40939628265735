<template>
    <div class="contentBox clearfix">
        <!--<div style="background: #fff;padding: 15px 10px">
            <span>数据概况</span>
            <el-divider></el-divider>
            <dataOverview></dataOverview>
        </div>-->
        <el-tabs class="tabs" v-model="activeNums">
            <el-tab-pane label="数据概览" name="first">
                <dataOverview ref="dataOverview" :type="activeNums == 'first' && 1" v-if="activeNums == 'first'"></dataOverview>
            </el-tab-pane>

            <el-tab-pane label="扫码分析" name="second">
                <scanAnalysis :type="activeNums == 'second' && 2" v-if="activeNums == 'second'" />
            </el-tab-pane>

            <el-tab-pane label="产品分析" name="third">
                <productAnalysis :type="activeNums == 'third' && 3" v-if="activeNums == 'third'" />
            </el-tab-pane>
			<!-- <el-tab-pane label="窜货分析" name="four">
			    <channelingAnalysis :type="activeNums == 'four' && 4" v-if="activeNums == 'four'" />
			</el-tab-pane> -->
        </el-tabs>
    </div>
</template>

<script>
    import dataOverview from "./scanIndex/DataOverview"
    import scanAnalysis from "./scanIndex/ScanAnalysis"
    import productAnalysis from "./scanIndex/productAnalysis"
	// import channelingAnalysis from "./scanIndex/channelingAnalysis"
    export default {
        name: "scanStatistics",
        components: {
            dataOverview,
            scanAnalysis,
            productAnalysis,
			// channelingAnalysis
        },
        data() {
            return {
                activeNums: 'first',
            }
        },
        created() {
        },
        methods: {
            refresh() {
                // console.log('开始执行刷新')
                // console.log('this.$refs',this.$refs)
                // console.log('this.$refs.dataOverview', this.$refs.dataOverview)
                // console.log('this.$refs.dataOverview.fetchMapData', this.$refs.dataOverview.fetchMapData())
                this.$refs.dataOverview.fetchMapData()
                this.$refs.dataOverview.scanAreaData()
                this.$refs.dataOverview.fetchDaysData()
                this.$refs.dataOverview.fetchYearData()
            }
        },
        mounted() {
        },
        beforeDestroy() {
        }
    }
</script>

<style scoped>
    .contentBox {
        width: 100%;
        height: auto;
    }
    /deep/.el-tabs--top .el-tabs__header {
        margin-bottom: 0px !important;
    }
</style>


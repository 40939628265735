import { render, staticRenderFns } from "./PayDialog.vue?vue&type=template&id=3a2da130&scoped=true&"
import script from "./PayDialog.vue?vue&type=script&lang=js&"
export * from "./PayDialog.vue?vue&type=script&lang=js&"
import style0 from "./PayDialog.vue?vue&type=style&index=0&id=3a2da130&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2da130",
  null
  
)

export default component.exports
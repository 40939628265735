<template>
  <div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="scanDialogVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="80%"
        >
        <commonTable
                :tableData="tableData"
                :loading="loading"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :currentPage="pageNum"
                :total="total"
        >
            <template v-slot:table>
              <el-table-column prop="warningCode" align="center" label="类型" width="300px">
                  <template slot-scope="scope">
                      <span v-if="scope.row.warningCode == 0">正常</span>
                      <span v-if="scope.row.warningCode == 1">异地扫码</span>
                      <span v-if="scope.row.warningCode == 2">扫码异常</span>
                      <span v-if="scope.row.warningCode == 3">验证异常</span>
                      <span v-if="scope.row.warningCode == 4">窜货提醒</span>
                  </template>
              </el-table-column>
              <el-table-column prop="warningCode" align="center" label="内容">
                  <template slot-scope="scope">
                  <div v-if="scope.row.warningCode == 0">
                  <div>{{scope.row.warningInfo}}</div>
                  </div>
                  <div v-if="scope.row.warningCode == 1">
                      <div><span>{{scope.row.createTime}}{{scope.row.title}}</span><span style="color: #2196f3;word-break:break-all;cursor:pointer;" class="tag-read" :data-clipboard-text="cHandle" @click="copyHandle(scope.row.handle)">{{scope.row.handle}}</span><span>在{{scope.row.address}}被{{scope.row.nickeName}}访问</span></div>
                      <div v-if="scope.row.lastTime">首次扫码信息：{{scope.row.lastTime}}在{{scope.row.lastAddress}}扫码</div>
                  </div>
                  <div v-if="scope.row.warningCode == 2">
                      <div><span>{{scope.row.createTime}}{{scope.row.title}}</span><span class="tag-read" :data-clipboard-text="cHandle" @click="copyHandle(scope.row.handle)" style="color: #2196f3;word-break:break-all;cursor:pointer;">{{scope.row.handle}}</span><span>第{{scope.row.num}}次访问</span></div>
                      <div>扫码次数上限为：{{scope.row.outValue}}次</div>
                  </div>
                  <div v-if="scope.row.warningCode == 3">
                      <div><span>{{scope.row.createTime}}{{scope.row.title}}</span><span class="tag-read" :data-clipboard-text="cHandle" @click="copyHandle(scope.row.handle)" style="color: #2196f3;word-break:break-all;cursor:pointer;">{{scope.row.handle}}</span><span>第{{scope.row.num}}次验证</span></div>
                      <div>验证次数超阈值为：{{scope.row.verificationNum}}次</div>
                  </div>
                  <div v-if="scope.row.warningCode == 4">
                      <div><span>{{scope.row.createTime}}{{scope.row.title}}</span><span style="color: #2196f3;word-break:break-all;cursor:pointer;" class="tag-read" :data-clipboard-text="cHandle" @click="copyHandle(scope.row.handle)">{{scope.row.handle}}</span><span>在{{scope.row.address}}被{{scope.row.nickeName}}访问</span></div>
                      <div>{{scope.row.dealerName}}(经销商)的销售区在{{scope.row.area}}</div>
                  </div>
                  </template>
              </el-table-column>
              <el-table-column prop="createTime" align="center" label="时间" width="400px"></el-table-column>
            </template>
        </commonTable>
    </el-dialog>
  </div>
</template>

<script>
    import Clipboard from 'clipboard'
    import commonTable from "@/components/common/commonTable";
    import { fetchWaringData } from "@/api/scanStatics"
    export default {
        name: "scanDialog",
        components: {
          commonTable
        },
        data() {
            return {
                scanDialogVisible: false,
                tableData: [],
                loading: false,
                pageSize: 10,
                pageNum: 1,
                total: 1,
                isToday: false,
                dialogTitle: '异常扫码',
                cHandle: '',
                cHandle2: '',
            }
        },
        methods: {
         openDialog(isToday) {
           this.pageNum = 1
           this.pageSize = 10
           this.isToday = isToday
           this.getTableData()
           this.scanDialogVisible = true
         },
         closeDialog() {
          this.scanDialogVisible = false
         },
         handleSizeChange(num) {
          this.pageSize = num;
          this.pageNum = 1;
          this.getTableData()
         },
         handleCurrentChange(num){
           this.pageNum = num
           this.getTableData()
         },
         getTableData() {
           let params={
             pageNum: this.pageNum,
             pageSize: this.pageSize,
             isFleeing: false,
             companyId: JSON.parse(localStorage.getItem('info')).companyId
           }
           fetchWaringData(params).then(res => {
              if(res.data) {
                  this.tableData = res.data.list
                  console.log(this.tableData)
                  this.total = res.data.total
              }
           })
         },
         // 获取浏览时长class颜色
          getTimeColor(time){
              let colorArr = ['grey','blue','orange']
              if(time && time < 60){
                  return colorArr[0]
              }else if(time && time >= 60 && time < 600 ) {
                  return colorArr[1]
              }else if(time >= 600) {
                  return colorArr[2]
              }
          },
          getTimeText(time) {
            if(time && time < 600){
              return time + 's'
            }else if(time >= 600) {
              return '600+'
            }else {
              return '-'
            }
          },
          // 复制
          copyHandle(data) {
              this.cHandle = data // data.customPageId
              var clipboard = new Clipboard('.tag-read')
              clipboard.on('success', e => {
                console.log('复制成功')
              })
              clipboard.on('error', e => {
                  // 不支持复制
                  console.log('该浏览器不支持复制')
                  // 释放内存
                  // clipboard.listener.destory()
                  this.$message({
                    type: 'warning',
                    message: '该浏览器不支持复制'
                  });
              })
              this.$message({
                type: 'success',
                message: '复制成功'
              });
          },
            copyHandle2(data) {
                this.cHandle2 = data // data.customPageId
                if(!data) {
                  return
                }
                var clipboard = new Clipboard('.tag-read')
                clipboard.on('success', e => {
                    console.log('复制成功')
                })
                clipboard.on('error', e => {
                    // 不支持复制
                    console.log('该浏览器不支持复制')
                    // 释放内存
                    // clipboard.listener.destory()
                    this.$message({
                        type: 'warning',
                        message: '该浏览器不支持复制'
                    });
                })
            }
        },
        filters: {
        },
        mounted() {


        }
    }
</script>

<style scoped>
.avatar{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}
.timeShowTit {
  font-size: 12px;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  height: 25px;
  padding: 3px 8px;
  line-height: 25px;
  margin: auto;
}
.timeShowTit.grey{
      background-color: #EAEFFF;
      color: #4A7AF0;
  }
.timeShowTit.blue{
      background-color: #8EABFF;
      color: #fff;
  }
.timeShowTit.orange{
      background-color: #FFAB52;
      color: #fff;
  }
.serialNumber{
    /* background-color: #8EABFF; */
    color: #333;
    padding: 0 5px;
    border-radius: 4px;
    height: 25px;
    padding: 3px 8px;
    line-height: 25px;
}
.tag-read{
  cursor: pointer;
  vertical-align: bottom;
  margin-left: 3px;
}
.tag-read img {
  vertical-align: sub;
}
</style>

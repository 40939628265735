<template>
	<div>
		<div v-if="tableData.length" class="scanTList myVisitListWrap" style="display: flex;justify-content: flex-start;">
			<div style="background-image: linear-gradient(to right , #F6F5F8, #FFFFFF);padding: 10px;width: 28%;min-height: 106px;line-height: 30px;margin-right: 30px;border-radius: 10px;"
				v-for="(item, index) in tableData" :key="index">
				<div class="title">
					<img v-if="item.warningCode == 1" src="../../../../assets/images/yd.png" alt="" width="30px"
						height="30px" style="margin-right: 10px;">
					<img v-if="item.warningCode == 2" src="../../../../assets/images/sm.png" alt="" width="30px"
						height="30px" style="margin-right: 10px;">
					<img v-if="item.warningCode == 3" src="../../../../assets/images/yz.png" alt="" width="30px"
						height="30px" style="margin-right: 10px;">
					<img v-if="item.warningCode == 4" src="../../../../assets/images/ch@2x.png" alt="" width="30px"
						height="30px" style="margin-right: 10px;">
					<div>{{ item.warningCode | setStatus }}</div>
				</div>
				<div class="name">{{item.className}}{{item.handle}}<span class="tag-read" :data-clipboard-text="cHandle"  @click="copyHandle(item.handle)"><img src="../../../../assets/images/fz.png" /></span></div>
        <div class="date"><span class="dealerNameSapn">{{item.dealerName}}</span>{{item.createTime}}</div>
			</div>
			<div @click="waringMore" style="height: 106px;width: 106px;background-image: linear-gradient(to right, #F6F5F8 70%, #FFFFFF);display: flex;align-items: center;justify-content: center;position: absolute;right: 0;top: 0;flex-direction: column;cursor: pointer;">
				<img src="../../../../assets/images/more.png" alt="" width="30px" height="30px">
				<div class="more">查看更多</div>
			</div>
		</div>
		<div v-else>
			<el-empty :image-size="100"></el-empty>
		</div>
		<!-- <WaringDialog ref="waringDialog"></WaringDialog> -->
    <FchDialog ref="fchDialog"></FchDialog>
	</div>
</template>

<script>
	// import commonTable from "@/components/common/commonTable";
	import Clipboard from 'clipboard'
	import FchDialog from './FchDialog'
	import {
		fetchWaringData
	} from "@/api/scanStatics";
	export default {
		name: "waringTable",
		components: {
			// commonTable,
			FchDialog
		},
		data() {
			return {
				tableData: [],
				pageSize: 1,
				currentPage: 1,
				loading: true,
				cHandle:'',
			}
		},
		mounted() {
			this.getTableData()
		},
		filters: {
			setStatus: function(type) {
				const statusList = {
					0: '正常',
					1: '异地扫码',
					2: '扫码异常',
				  3: '验证异常',
					4: '窜货提醒',
				}
				return statusList[type]
			}
		},
		methods: {
			// 复制
        copyHandle(data) {
              this.cHandle = data // data.customPageId
              var clipboard = new Clipboard('.tag-read')
              clipboard.on('success', e => {
                console.log('复制成功')
                
              })
              clipboard.on('error', e => {
                  // 不支持复制
                  console.log('该浏览器不支持复制')
                  // 释放内存
                  // clipboard.listener.destory()
                  this.$message({
                    type: 'warning',
                    message: '该浏览器不支持复制'
                  });
              })
              this.$message({
                type: 'success',
                message: '复制成功'
              });
          },
			waringMore() {
			    this.$refs.fchDialog.openDialog()
			},
			changeMoney(num) {
				var regexp = /(?:\.0*|(\.\d+?)0+)$/
				if (num > 1000000) {
					num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
					return num + 'w'
				} else {
					num = (num / 100).toFixed(2)
					num = num.replace(regexp, '$1')
					return num
				}
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.queryPage();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.queryPage();
			},
			getTableData() {
				fetchWaringData({
          companyId: JSON.parse(localStorage.getItem('info')).companyId,
          isFleeing: true,
				}).then(res => {
					this.loading = false
					if (res.code == 200) {
              this.tableData = res.data.list.slice(0,3)
					}
				}).catch(() => {
					this.loading = false
				})
			}
		}
	}
</script>

<style scoped>
	.scanTList {
		width: 96%;
		margin: auto;
		padding-bottom: 20px;
		position: relative;
	}

	.avatorImg {
		width: 30px;
		height: 30px;
		border-radius: 30px;
		vertical-align: middle;
	}

	.title {
		font-size: 18px;
		font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
		font-weight: 400;
		color: #1F1D1D;
		display: flex;
		align-items: center;
	}

	.name {
		font-size: 14px;
		font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
		font-weight: 400;
		color: #1F1D1D;
		padding-left: 40px;
	}

	.date {
		font-size: 14px;
		font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
		font-weight: 400;
		color: #AAB3CB;
		padding-left: 40px;
	}
	.dealerNameSapn{
    color:rgb(33, 150, 243);
    margin-right: 20px;
  }
	.more {
		font-size: 14px;
		font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
		font-weight: 400;
		color: #A8B3CE;
		line-height: 20px;
	}
	.tag-read{
  cursor: pointer;
  vertical-align: bottom;
  margin-left: 3px;
}
.tag-read img {
  vertical-align: sub;
}
</style>

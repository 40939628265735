<template>
    <div>
        <template v-if="chartData && chartData.length">
        <div class="charts-box" style="width: 100%;height: 280px;">
            <div class="lineBox" id="lineBox" style="width: 100%;height: 260px"></div>
        </div>
        <div class="nameList">
            <template v-if="chartData && chartData.length">
                <div v-for="(item,index) in chartData" :key="index">
                    <span class="circular" :style="{'background':colorConfirm(index)}"></span> {{item.name}}
                </div>
            </template>
        </div>
        </template>
        <template v-else>
            <div class="noDataText">暂无数据</div>
        </template>
    </div>
</template>

<script>
    import echarts from "echarts";
    import { fetchProdTopData } from "@/api/scanStatics";
    export default {
        data() {
            return {
                buyData: [],
                chartData: [{
                        data: [100, 200, 300, 400, 200, 100],
                        type: 'line',
                        name: "潞安娜",
                        legendShape: 'circle'
                    },{
                        data: [100, 200, 300, 400, 200, 200],
                        type: 'line',
                        name: "潞安娜1",
                        legendShape: 'circle'
                    }]

            }
        },
        created() {

        },
        methods: {
            colorConfirm(index) {
                // console.log(index)
                if (index == 0) {
                    return '#4767F0'
                } else if (index == 1) {
                    return '#FFA624'
                } else if (index == 2) {
                    return '#32C779'
                } else if (index == 3) {
                    return '#43DBFF'
                } else if (index == 4) {
                    return '#C66CD9'
                }
            },
            getData() {
                let v = this
                let arrs = []
                let arrName = []
                fetchProdTopData({
                    monthNum: 6,
                    num: 5
                }).then(res => {
                    if (res) {
                        console.log('chartData',res)
                        this.chartData = res.data
                        let XData=res.data[0].brokenLineVos.map(item => item.time +'月' )
                        res.data.forEach((items,index) => {
                            let arr = items.brokenLineVos.map(item => item.num)
                            let str = {
                                name: items.name,
                                data: arr
                            }
                            arrName.push(items.name)
                            arrs.push(str)
                        })
                      //  console.log('arrs',arrs);
                       v.drawLine1(XData, arrName, arrs);
                    } else {}
                }).catch(() => {})
            },
            // echarts
            drawLine1(XData,aName,arrs) {
                let myChart = this.$echarts.init(document.getElementById('lineBox'))
                let serArr = []
                let cList = ['#4767F0','#FFA624','#32C779','#43DBFF','#C66CD9']
                arrs.forEach((item,index) => {
                    let st = {
                            name: item.name,
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            symbolSize: 1,
                            zlevel: 3,
                            itemStyle:{
                                color:'#19a3df',
                                borderColor:"#a3c8d8",
                            },
                            lineStyle: {
                                normal: {
                                    width: 2,
                                    color: cList[index],
                                }
                            },
                            data: item.data,
                        }
                    serArr.push(st)
                })
                // console.log('serArr',serArr);
                var option = {
                    backgroundColor:"#fff",
                    grid: {
                        top: 20,
                        left: 60,
                        bottom: 30
                        // containLabel: true
                    },
                    legend: {
                        show: false,
                        x: 'center',
                        y: '5',
                        itemWidth: 10,
                        itemHeight: 10,
                        textStyle: {
                            color: '#1bb4f6'
                        },
                        data: aName
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    xAxis: [{
                        type: 'category',
                        // name: '地区',
                        boundaryGap: false,
                        // data: ['03-16', '03-17', '03-18','03-19','03-20','03-21','03-22'],
                        data: XData,
                        "axisLabel": {
                            "color": "#c0c3cd",//X轴文字
                            "fontSize": 12,
                            "interval": 0
                        },
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLine: {
                            show: false,
                            color:'#c0c3cd'
                        },
                        splitLine:{
                            show: false, // X轴线 颜色类型的修改
                            lineStyle: {
                                type: 'dashed',
                                color: '#292c38'
                            }
                        }
                    }],
                    yAxis: [{
                        type: 'value',
                        // name: '数量',
                        min: 0,
                        axisLabel: {
                            formatter: '{value}',
                            color: "#c0c3cd",//X轴文字
                            fontSize: 12,
                            interval: 0
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }],
                    series: serArr
                };
                myChart.setOption(option)
                myChart.setOption(option)
                window.addEventListener("resize", () => {
			        myChart.resize();
			    });
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .nameList {
        display: flex;
        flex-wrap: wrap;
        padding: 0 30px 15px;
    }

    .nameList>div {
        width: auto;
        text-align: left;
        color: #939EB1;
        font-size: 14px;
        overflow: hidden;
        /*自动隐藏文字*/
        text-overflow: ellipsis;
        /*文字隐藏后添加省略号*/
        white-space: nowrap;
        margin-right: 10px;
        /*强制不换行*/
    }

    .circular {
        display: inline-block;
        width: 10px;
        height: 10px;
        /* background-color: red; */
        border-radius: 50%;
        margin-right: 10px;
    }
    .noDataText{
        text-align: center;
        font-size: 14px;
        padding: 20px 0;
        color: #909399;
    }
</style>

<template>
  <el-dialog
          :title="dialogTitle"
          :visible.sync="dialogVisible"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
          width="80%"
          >
     <commonTable
          :tableData="tableData"
          :loading="loading"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :currentPage="pageNum"
          :total="total"
      >
       <template v-slot:table v-if="dialogVisible">
           <template v-if="param.source === 0">
              <el-table-column prop="businessCardName" align="center" label="名片">
                <template slot-scope="scope">
                  <template v-if="post === 1">
                    <span>{{scope.row.businessCardName? scope.row.businessCardName : '---'}}</span>
                  </template>
                  <template v-else>
                    <span>{{scope.row.title? scope.row.title : '---'}}</span>
                  </template>
                </template>
              </el-table-column>
           </template>
           <template v-else>
              <el-table-column prop="title" align="center" label="产品">
                <template slot-scope="scope">
                  <span>{{scope.row.title? scope.row.title : '---'}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="title" align="center" label="产品序列号">
                <template slot-scope="scope">
                  <span>{{scope.row.serialNumber? scope.row.serialNumber : '---'}}</span>
                </template>
              </el-table-column>
           </template>

           <el-table-column prop="handle" align="center" label="标识" width="200">
              <template slot-scope="scope">
                <span>{{scope.row.handle? scope.row.handle : '---'}}</span>
                <span v-if="scope.row.handle" class="tag-read" :data-clipboard-text="cHandle"  @click="copyHandle(scope.row.handle)"><img src="../../../../assets/images/fz.png" /></span>
              </template>
            </el-table-column>
             <el-table-column prop="nickeName" align="center" label="客户">
            <template slot-scope="scope">
              <img :src="scope.row.avatarUrl ? scope.row.avatarUrl : 'https://mzmpic.oss-cn-beijing.aliyuncs.com/hstx.png'" class="avatar" /><span>{{ scope.row.nickeName ? scope.row.nickeName : '微信用户'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="访问时间">
              <template slot-scope="scope">
                {{ scope.row.createTime? scope.row.createTime : '---' }}
              </template>
            </el-table-column>
          <el-table-column prop="address" align="center" label="访问地址">
              <template slot-scope="scope">
                {{ scope.row.address? scope.row.address : '---' }}
              </template>
            </el-table-column>
          <el-table-column prop="seeTime" align="center" label="浏览时长">
              <template slot-scope="scope">
                <template v-if="scope.row.seeTime">
                  <span class="time_span" :class="getTimeColor(scope.row.seeTime)"> 
                    {{ getTimeText(scope.row.seeTime)}}
                  </span>
                </template>
                <template v-else>
                  ---
                </template>
              </template>
            </el-table-column>

       </template>
     </commonTable>
  </el-dialog>
</template>
<script>
import Clipboard from 'clipboard'
import commonTable from "@/components/common/commonTable";
import { fetchCategoryDetail, fetchCategoryDetailAddr } from "@/api/scanStatics";
export default {
  data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      param: null,
      tableData: [],
      loading: false,
      cHandle: '',
      poat: '',
    }
  },
  components: {
    commonTable
  },
  methods: {
    openDialog(data,post) {
      this.tableData = []
      this.total = 0
      this.pageNum = 1
      this.pageSize = 10
      this.param = data
      this.post = post
      this.getTableData()
    },
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getTableData()
    },
    handleCurrentChange(num){
      this.pageNum = num
      this.getTableData()
    },
    getTableData() {
      if(this.post === 1) {
        let params = {...this.param, pageNum: this.pageNum, pageSize: this.pageSize}
        fetchCategoryDetail(params).then(res => {
          this.tableData = res.data.list ? res.data.list : []
          this.total = res.data.total ? res.data.total : 0
          this.dialogVisible = true
        })
      }else if(this.post === 2) {
        let params = {...this.param, pageNum: this.pageNum, pageSize: this.pageSize}
        fetchCategoryDetailAddr(params).then(res => {
          this.tableData = res.data.list ? res.data.list : []
          this.total = res.data.total ? res.data.total : 0
          this.dialogVisible = true
        })
      }

    },
    closeDialog() {
      this.dialogVisible = false
    },
    // 复制
    copyHandle(data) {
        this.cHandle = data // data.customPageId
        var clipboard = new Clipboard('.tag-read')
        clipboard.on('success', e => {
          console.log('复制成功')
          
        })
        clipboard.on('error', e => {
            // 不支持复制
            console.log('该浏览器不支持复制')
            // 释放内存
            // clipboard.listener.destory()
            this.$message({
              type: 'warning',
              message: '该浏览器不支持复制'
            });
        })
        this.$message({
          type: 'success',
          message: '复制成功'
        });
    },
     // 获取浏览时长class颜色
    getTimeColor(time){
        let colorArr = ['grey','blue','orange']
        if(time && time < 60){
            return colorArr[0]
        }else if(time && time >= 60 && time < 600 ) {
            return colorArr[1]
        }else if(time >= 600) {
            return colorArr[2]
        }
    },
    getTimeText(time) {
      if(time && time < 600){
        return time + 's'
      }else if(time >= 600) {
        return '600+'
      }else {
        return '-'
      }
    },
  }
}
</script>
<style scoped>
.avatar{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}
.time_span{
  padding: 2px 14px;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
}
.grey{
      background-color: #EAEFFF;
      color: #4A7AF0;
  }
.blue{
      background-color: #8EABFF;
      color: #fff;
  }
.orange{
      background-color: #FFAB52;
      color: #fff;
  }
.tag-read{
  cursor: pointer;
  vertical-align: bottom;
  margin-left: 3px;
}
.tag-read img {
  vertical-align: sub;
}
</style>